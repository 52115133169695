import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
const SearchUi = ({ searchQuery, handleSearch, styles }: SearchProops) => {
  return (
    <TextField
      variant="outlined"
      placeholder="Search"
      value={searchQuery}
      onChange={handleSearch}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      style={styles}
    />
  );
};
export default SearchUi;

interface SearchProops {
  searchQuery: string;
  handleSearch: (e: any) => void;
  styles?: React.CSSProperties;
}
