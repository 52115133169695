import Color from "src/theme/colors";

const Styles = {
  mainContiner: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  conatinerComman: {
    width: "100%",
    marginTop: 30,
  },
  validationError: {
    marginTop: 8,
    color: Color.red,
  },
  width100: {
    width: "100%",
  },
  richTextBox: {
    marginBottom: 15,
  },
};

export default Styles;
