import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material";
import { SwitchBaseProps } from "@mui/material/internal/SwitchBase";

export interface ItemProps {
  id: number;
  name: string;
  value: boolean;
}

interface CheckBoxUiProps {
  itemList?: ItemProps[];
  onChange?: (e: any, index: number) => void;
  onValue?: (category: any) => number;
  defaultValue?: any;
  data?: any;
}

const CheckBoxUi = ({ itemList, onChange }: CheckBoxUiProps) => {
  return (
    <FormGroup sx={{ flexDirection: "row" }}>
      {itemList.map((x, index) => {
        return (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={x.value}
                onChange={(e) => {
                  onChange(e, index);
                }}
              />
            }
            label={x.name}
          />
        );
      })}
    </FormGroup>
  );
};
export default CheckBoxUi;
