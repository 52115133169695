import { OutlinedInputProps, TextField } from "@mui/material";

const InputFiles = ({ onChange }: InputFilesProps) => {
  return (
    <TextField
      id="recipe-name"
      type="file"
      variant="outlined"
      style={{ width: "100%" }}
      onChange={onChange}
    />
  );
};
export default InputFiles;

interface InputFilesProps {
  onChange?: OutlinedInputProps["onChange"];
}
