import { Suspense, lazy, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import Login from "./content/applications/authPages/login";
import getUserData from "./utils/getLocalStorageData";
import UserDetail from "./content/applications/User/userDetailPage";
import Overview from "../src/content/dashboards/OverView";
import User from "./content/applications/User";
import Recipe from "./content/applications/Recipe";
import AddRecipe from "./content/applications/addRecipe/addRecipe";
import RecipeDetail from "./content/applications/Recipe/recipeDetailPage";
import EditRecipe from "./content/applications/Recipe/editRecipe";

// import AddRecipe from './content/applications/addRecipe/addRecipe';

const isLogin = getUserData();

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// const Overview = Loader(lazy(() => import("src/content/overview")));

// Dashboards

// const Overview = Loader(lazy(() => import("src/content/dashboards/OverView")));

// // Applications

// const Messenger = Loader(
//   lazy(() => import("src/content/applications/Messenger"))
// );

// const AddRecipe = Loader(
//   lazy(() => import("src/content/applications/addRecipe/addRecipe"))
// );
// const User = Loader(lazy(() => import("src/content/applications/User")));
// const Recipe = Loader(lazy(() => import("src/content/applications/Recipe")));
// const UserProfile = Loader(
//   lazy(() => import("src/content/applications/Users/profile"))
// );
// const UserSettings = Loader(
//   lazy(() => import("src/content/applications/Users/settings"))
// );

// // Components

// const Buttons = Loader(
//   lazy(() => import("src/content/pages/Components/Buttons"))
// );
// const Modals = Loader(
//   lazy(() => import("src/content/pages/Components/Modals"))
// );
// const Accordions = Loader(
//   lazy(() => import("src/content/pages/Components/Accordions"))
// );
// const Tabs = Loader(lazy(() => import("src/content/pages/Components/Tabs")));
// const Badges = Loader(
//   lazy(() => import("src/content/pages/Components/Badges"))
// );
// const Tooltips = Loader(
//   lazy(() => import("src/content/pages/Components/Tooltips"))
// );
// const Avatars = Loader(
//   lazy(() => import("src/content/pages/Components/Avatars"))
// );
// const Cards = Loader(lazy(() => import("src/content/pages/Components/Cards")));
// const Forms = Loader(lazy(() => import("src/content/pages/Components/Forms")));

// // Status

// const Status404 = Loader(
//   lazy(() => import("src/content/pages/Status/Status404"))
// );
// const Status500 = Loader(
//   lazy(() => import("src/content/pages/Status/Status500"))
// );
// const StatusComingSoon = Loader(
//   lazy(() => import("src/content/pages/Status/ComingSoon"))
// );
// const StatusMaintenance = Loader(
//   lazy(() => import("src/content/pages/Status/Maintenance"))
// );

const routes: RouteObject[] = [
  {
    path: "*",
    element: <SidebarLayout />,
    children: [
      {
        path: "*",
        element: <Overview />,
      },
    ],
  },
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Overview />,
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
  {
    path: "dashboards",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="overview" />,
      },
      {
        path: "*",
        element: <Navigate to="overview" />,
      },
      // {
      //   path: "",
      //   element: <Navigate to="overView" />,
      // },
      {
        path: "overview",
        element: <Overview />,
      },
      // {
      //   path: "messenger",
      //   element: <Messenger />,
      // },
    ],
  },
  {
    path: "management",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="users" />,
      },
      {
        path: "*",
        element: <Navigate to="users" />,
      },
      // {
      //   path: "",
      //   element: <Navigate to="user" />,
      // },
      {
        path: "users",
        element: <User />,
      },
      {
        path: "userdetail",
        element: <UserDetail />,
      },
      {
        path: "recipes",
        element: <Recipe />,
      },
      {
        path: "addrecipe",
        element: <AddRecipe />,
      },
      {
        path: "recipedetail",
        element: <RecipeDetail />,
      },
      {
        path: "editrecipe",
        element: <EditRecipe />,
      },

      // {
      //   path: "profile",
      //   children: [
      //     {
      //       path: "",
      //       element: <Navigate to="details" replace />,
      //     },
      //     {
      //       path: "details",
      //       element: <UserProfile />,
      //     },
      //     {
      //       path: "settings",
      //       element: <UserSettings />,
      //     },
      //   ],
      // },
    ],
  },
];

export default routes;

// const routes: RouteObject[] = [
//   {
//     path: '',
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: '/',
//         // element: <Overview />
//         element: <Overview />

//       },
//       {
//         path: 'overview',
//         element: <Navigate to="/" replace />
//       },
//       {
//         path: 'status',
//         children: [
//           {
//             path: '',
//             element: <Navigate to="404" replace />
//           },
//           {
//             path: '404',
//             element: <Status404 />
//           },
//           {
//             path: '500',
//             element: <Status500 />
//           },
//           {
//             path: 'maintenance',
//             element: <StatusMaintenance />
//           },
//           {
//             path: 'coming-soon',
//             element: <StatusComingSoon />
//           }
//         ]
//       },
//       {
//         path: '*',
//         element: <Status404 />
//       }
//     ]
//   },
//   {
//     path: 'dashboards',
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: '',
//         element: <Navigate to="crypto" replace />
//       },
//       {
//         path: 'crypto',
//         element: <Crypto />
//       },
//       {
//         path: 'messenger',
//         element: <Messenger />
//       }
//     ]
//   },
//   {
//     path: 'management',
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: '',
//         element: <Navigate to="transactions" replace />
//       },
//       {
//         path: 'transactions',
//         element: <Transactions />
//       },
//       {
//         path: 'profile',
//         children: [
//           {
//             path: '',
//             element: <Navigate to="details" replace />
//           },
//           {
//             path: 'details',
//             element: <UserProfile />
//           },
//           {
//             path: 'settings',
//             element: <UserSettings />
//           }
//         ]
//       }
//     ]
//   },
//   {
//     path: '/components',
//     element: <SidebarLayout />,
//     children: [
//       {
//         path: '',
//         element: <Navigate to="buttons" replace />
//       },
//       {
//         path: 'buttons',
//         element: <Buttons />
//       },
//       {
//         path: 'modals',
//         element: <Modals />
//       },
//       {
//         path: 'accordions',
//         element: <Accordions />
//       },
//       {
//         path: 'tabs',
//         element: <Tabs />
//       },
//       {
//         path: 'badges',
//         element: <Badges />
//       },
//       {
//         path: 'tooltips',
//         element: <Tooltips />
//       },
//       {
//         path: 'avatars',
//         element: <Avatars />
//       },
//       {
//         path: 'cards',
//         element: <Cards />
//       },
//       {
//         path: 'forms',
//         element: <Forms />
//       }
//     ]
//   }
// ];

// export default routes;
