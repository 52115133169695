import { useEffect, useRef, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";

import InboxTwoToneIcon from "@mui/icons-material/InboxTwoTone";
import { styled } from "@mui/material/styles";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import getUserData from "src/utils/getLocalStorageData";
import FontStyles from "src/utils/font";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const user = {
    name: "Catherine Pike",
    avatar: "/static/images/avatars/1.jpg",
    jobtitle: "Project Manager",
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({});

  const navigate = useNavigate();
  useEffect(() => {
    let temp = getUserData();
    setUserData(temp);
  }, []);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const logOut = () => {
    localStorage.removeItem("sessiondata");
    navigate("/");
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {/* <Avatar variant="rounded" alt={user.name} src={user.avatar} /> */}
        {/* <Hidden mdDown> */}
        <UserBoxText>
          <Typography
            textAlign={"center"}
            sx={{ marginTop: 1, marginBottom: 1, ...FontStyles.font20Prociono }}
          >
            {userData?.name}
          </Typography>
          {/* <UserBoxLabel variant="body1">{userData?.name}</UserBoxLabel> */}
          {/* <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription> */}
        </UserBoxText>
        {/* </Hidden> */}
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ width: 200 }}>
          <Typography
            textAlign={"center"}
            sx={{ marginTop: 1, marginBottom: 1, ...FontStyles.font20Prociono }}
          >
            {userData?.name}
          </Typography>
          <Divider sx={{ mb: 0 }} />
          <Divider />
          <Typography
            textAlign={"center"}
            sx={{ marginTop: 1, marginBottom: 1, ...FontStyles.font16Prociono }}
          >
            {userData?.email}
          </Typography>
        </Box>
        {/* <MenuUserBox sx={{ minWidth: 210 }} display="flex">
         
          <UserBoxText>
            <UserBoxLabel variant="body1">{userData?.name}</UserBoxLabel>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            <UserBoxDescription variant="body2">
              {userData?.email}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox> */}
        <Divider sx={{ mb: 0 }} />
        <Divider />

        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={logOut}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
