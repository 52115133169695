import { Card } from "@mui/material";
import UsersTable from "./UsersTable";

function UserListData() {
  return (
    <Card sx={{ marginTop: 5 }}>
      <UsersTable />
    </Card>
  );
}

export default UserListData;
