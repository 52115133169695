import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Avatar,
  alpha,
  Tooltip,
  CardActionArea,
  styled,
} from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import CardDashborad from "src/components/cardDahborad";
import { useEffect, useState } from "react";
import { GET } from "src/config/https";
import APIS from "src/config/api";
import getUserData from "src/utils/getLocalStorageData";
import { Divider } from "@material-ui/core";
import FontStyles from "src/utils/font";
import Color from "src/theme/colors";

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    margin: ${theme.spacing(2, 0, 1, -0.5)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(0.5)};
    border-radius: 60px;
    height: ${theme.spacing(5.5)};
    width: ${theme.spacing(5.5)};
    background: ${theme.palette.mode === "dark"
      ? theme.colors.alpha.trueWhite[30]
      : alpha(theme.colors.alpha.black[100], 0.07)
    };
  
    img {
      background: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0.5)};
      display: block;
      border-radius: inherit;
      height: ${theme.spacing(4.5)};
      width: ${theme.spacing(4.5)};
    }
`
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[10]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        transition: ${theme.transitions.create(["all"])};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);

function Wallets({ metrixData, tabId }: WalletsData) {
  // const [metrixData, setMatrixData] = useState<any>({});
  // const [currentWeekRecipeLable, setCurrentWeekRecipeLable] = useState([])
  // const [currentWeekRecipeCount, setCurrentWeekRecipeCount] = useState([])

  // useEffect(() => {
  //   fetchMetrixData();
  // }, []);

  // useEffect(() => {
  //   if (metrixData) {
  //     let newArray = []
  //     metrixData?.most10UsedRecipesInCurrentMonth && metrixData?.most10UsedRecipesInCurrentMonth.map((x) => {
  //       newArray.push(x?.recipeResponse?.name)
  //       return
  //     })
  //     setCurrentWeekRecipeLable(newArray)

  //     let newArrayCount = []
  //     metrixData?.most10UsedRecipesInCurrentMonth && metrixData?.most10UsedRecipesInCurrentMonth.map((x) => {
  //       newArrayCount.push(x?.recipeResponse?.name)
  //       return
  //     })
  //     setCurrentWeekRecipeCount(newArrayCount)
  //   }
  // }, metrixData)


  // const fetchMetrixData = async () => {
  //   let tempUserData = getUserData();
  //   if (!tempUserData) return;
  //   let param = {};
  //   let resp = await GET(APIS.GET_MATRIX, param, tempUserData.jwtToken).then(
  //     (res: any) => {
  //       if (res.isSuccess) {

  //         setMatrixData(res.result);
  //       }
  //     }
  //   );
  // };
  return (
    <>

      <Typography sx={{ ...FontStyles.font20PlayfairDisplaySC, color: Color.color5 }}>Users</Typography>
      <Divider style={{ marginTop: 1, marginBottom: 15 }} />
      <Grid container spacing={3} >

        {tabId === 0 ? <CardDashborad
          lable={"Total Number Of"}
          time={"Signup Users"}
          name={
            metrixData.totalNumberOfUserSignUp && metrixData
              ? metrixData.totalNumberOfUserSignUp
              : 0
          }
        /> : null}
        {tabId === 0 || tabId === 1 ? <CardDashborad
          lable={"Login Users"}
          time={"(Current Week)"}
          name={
            metrixData.logedOnUserInCurrentWeek && metrixData
              ? metrixData.logedOnUserInCurrentWeek
              : 0
          }
        /> : null}
        {tabId === 0 || tabId === 2 ? <CardDashborad
          lable={"Login Users"}
          time={"(Current Month)"}
          name={
            metrixData.logedOnUserInCurrentMonth && metrixData
              ? metrixData.logedOnUserInCurrentMonth
              : 0
          }
        /> : null}
        {tabId === 0 || tabId === 1 ? <CardDashborad
          lable={"Signup Users"}
          time={"(Current Week)"}
          name={
            metrixData.signUpUserInCurrentWeek && metrixData
              ? metrixData.signUpUserInCurrentWeek
              : 0
          }
        /> : null}
        {tabId === 0 || tabId === 2 ? <CardDashborad
          lable={"Signup Users"}
          time={"(Current Month)"}
          name={
            metrixData.signUpUserInCurrentMonth && metrixData
              ? metrixData.signUpUserInCurrentMonth
              : 0
          }
        /> : null}


      </Grid>
      <Typography sx={{ ...FontStyles.font20PlayfairDisplaySC, color: Color.color5, marginTop: 1.5 }}>Subscription</Typography>
      <Divider style={{ marginTop: 1, marginBottom: 15 }} />
      <Grid container spacing={3} >
        {tabId === 0 ? <CardDashborad
          lable={"Total Number Of"}
          time={"Subscribed Users"}
          name={
            metrixData.totalNumberOfSubscribedUser && metrixData
              ? metrixData.totalNumberOfSubscribedUser
              : 0
          }
        /> : null}
        {tabId === 0 || tabId === 1 ? <CardDashborad
          lable={"New User Subscription"}
          time={"(Current Week)"}
          name={
            metrixData.newUserSubscriptionInCurrentWeek && metrixData
              ? metrixData.newUserSubscriptionInCurrentWeek
              : 0
          }
        /> : null}
        {tabId === 0 || tabId === 2 ? <CardDashborad
          lable={"New User Subscription"}
          time={"(Current Month)"}
          name={
            metrixData.newUserSubscriptionInCurrentMonth && metrixData
              ? metrixData.newUserSubscriptionInCurrentMonth
              : 0
          }
        /> : null}
        {tabId === 0 || tabId === 1 ? <CardDashborad
          lable={"Canceled Subscription"}
          time={"(Current Week)"}
          name={
            metrixData.canceledSubscriptionInCurrentWeek && metrixData
              ? metrixData.canceledSubscriptionInCurrentWeek
              : 0
          }
        /> : null}
        {tabId === 0 || tabId === 2 ? <CardDashborad
          lable={"Canceled Subscription"}
          time={"(Current Month)"}
          name={
            metrixData.canceledSubscriptionInCurrentMonth && metrixData
              ? metrixData.canceledSubscriptionInCurrentMonth
              : 0
          }
        /> : null}

      </Grid>
      {/* <Grid container spacing={3}>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1
            }}
          >
            <CardContent>
              <AvatarWrapper>
                <img
                  alt="BTC"
                  src="/static/images/placeholders/logo/bitcoin.png"
                />
              </AvatarWrapper>
              <Typography variant="h5" noWrap>
                Bitcoin
              </Typography>
              <Typography variant="subtitle1" noWrap>
                BTC
              </Typography>
              <Box
                sx={{
                  pt: 3
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  $3,586.22
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  1.25843 BTC
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1
            }}
          >
            <CardContent>
              <AvatarWrapper>
                <img
                  alt="Ripple"
                  src="/static/images/placeholders/logo/ripple.png"
                />
              </AvatarWrapper>
              <Typography variant="h5" noWrap>
                Ripple
              </Typography>
              <Typography variant="subtitle1" noWrap>
                XRP
              </Typography>
              <Box
                sx={{
                  pt: 3
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  $586.83
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  5,783 XRP
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1
            }}
          >
            <CardContent>
              <AvatarWrapper>
                <img
                  alt="Cardano"
                  src="/static/images/placeholders/logo/cardano.png"
                />
              </AvatarWrapper>
              <Typography variant="h5" noWrap>
                Cardano
              </Typography>
              <Typography variant="subtitle1" noWrap>
                ADA
              </Typography>
              <Box
                sx={{
                  pt: 3
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  $54,985.00
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  34,985 ADA
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Tooltip arrow title="Click to add a new wallet">
            <CardAddAction>
              <CardActionArea
                sx={{
                  px: 1
                }}
              >
                <CardContent>
                  <AvatarAddWrapper>
                    <AddTwoToneIcon fontSize="large" />
                  </AvatarAddWrapper>
                </CardContent>
              </CardActionArea>
            </CardAddAction>
          </Tooltip>
        </Grid>
      </Grid> */}
    </>
  );
}

export default Wallets;

interface WalletsData {
  metrixData: any
  tabId: number
}