import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
const ButtonUI = ({
  type,
  fullWidth,
  variant,
  sx,
  styles,
  label,
  image,
  color = "secondary",
  onClick,
  icon,
  isLoading,
  fontStyle,
  isDisable,
}: ButtonUiProps) => {
  const useStyles: any = makeStyles((theme) => ({
    customColor: {
      color: "blue",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Button
        sx={sx}
        variant={variant}
        fullWidth={fullWidth}
        type={type}
        style={styles}
        color={isDisable ? color : undefined}
        onClick={onClick}
        disabled={isDisable}
      >
        {image && (
          <Avatar src={image} sx={{ width: 24, height: 24, marginRight: 1 }} />
        )}
        {/* {label && !isLoading ? label : icon} */}
        {label && !isLoading ? (
          <Typography variant="body1" style={fontStyle}>
            {label}
          </Typography>
        ) : (
          icon
        )}
        {isLoading ? (
          <CircularProgress size={"1rem"} className={classes.customColor} />
        ) : null}
      </Button>
    </>
  );
};
export default ButtonUI;

interface ButtonUiProps {
  type?: "submit" | "reset" | "button" | undefined;
  fullWidth?: boolean;
  variant: "text" | "outlined" | "contained";
  sx?: SxProps<Theme>;
  styles?: React.CSSProperties;

  label?: string;
  image?: string;
  onClick?: () => void;
  icon?: any;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  isLoading?: boolean;
  fontStyle?: React.CSSProperties;
  isDisable?: boolean;
}
