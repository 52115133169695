// const BaseUrl = "https://dev-api.miseenplacemeals.com/api/";

const BaseUrl = process.env.REACT_APP_API_URL
const APIS = {
  LOGIN: BaseUrl + "Admin/authenticate",
  ADD_RECIPE: BaseUrl + "Recipes",
  UPDATE_RECIPE: BaseUrl + "Recipes",

  GET_USER: BaseUrl + "User/users",
  REFRESH_TOKEN: BaseUrl + "Admin/refresh-token",
  DELETE_USER: BaseUrl + "User",
  GET_USER_BY_ID: BaseUrl + "User",
  GET_ALL_RECIPE: BaseUrl + "Recipes/GetAll",
  GET_RECIPE_BY_ID: BaseUrl + "Recipes",
  DELETE_RECIPE: BaseUrl + "Recipes",
  UPDATE_USER: BaseUrl + "User",
  GET_RECIPE_COUNT: BaseUrl + "Recipes/count",
  GET_USER_COUNT: BaseUrl + "User/count",
  GET_SUBCRIPTION_USER_BY_ID: BaseUrl + "Payment/verify-subscription-by-userId?",
  GET_MATRIX:BaseUrl+"Metrics/metrics"

};

export default APIS;
