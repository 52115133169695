import { RouteObject } from "react-router";
import Login from "./content/applications/authPages/login";

const routes: RouteObject[] = [
  {
    path: "*",
    element: <Login />,
  },
  {
    path: "login",
    element: <Login />,
  },
];
export default routes;
