import { Chart } from "chart.js";
import { useEffect, useRef } from "react";

const BarChart = ({ labelData, valueData, headingData, titleData }: BarChart) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const myChartRef = chartRef?.current.getContext("2d");

        // Destroy the previous chart instance if it exists
        if (chartInstanceRef?.current) {
            chartInstanceRef?.current.destroy();
        }

        chartInstanceRef.current = new Chart(myChartRef, {
            type: "bar",
            data: {
                // labels: ["Login user in current week", "Login sser in current month", "SignUp User In Current Week", "SignUp User In Current Month", "Total Number Of User SignUp"],
                labels: labelData,
                datasets: [
                    {
                        label: titleData,
                        // data: [5, 5, 8, 19, 57],
                        data: valueData,

                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(153, 102, 255, 0.2)",
                            "rgba(255, 159, 64, 0.2)",
                            'rgba(255, 0, 0, 0.2)',
                            'rgba(0, 255, 0, 0.2)',
                            'rgba(0, 0, 255, 0.2)',
                            'rgba(0, 128, 0, 0.2)',
                            "rgba(253,180,92,0.2)",
                            'rgba(255, 165, 0, 0.2)',

                        ],
                        borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(153, 102, 255, 1)",
                            "rgba(255, 159, 64, 1)",
                            'rgba(255, 0, 0, 1)',
                            'rgba(0, 255, 0, 1)',
                            'rgba(0, 0, 255, 1)',
                            'rgba(0, 128, 0, 1)',
                            "rgba(253,180,92,1)",
                            'rgba(255, 165, 0, 1)',

                        ],
                        borderWidth: 1,
                        barThickness: 40,
                    },
                ],
            },
            options: {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        beginAtZero: true,
                        stacked: true,
                    },
                },
                plugins: {
                    title: {
                        display: true,
                        text: headingData,
                        font: {
                            size: 16,
                        },
                    },
                    legend: {
                        display: true,
                        position: "bottom",
                    },
                },
            },
        });

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef?.current) {
                chartInstanceRef?.current.destroy();
            }
        };
    }, [labelData, valueData, headingData, titleData]);

    return (<canvas ref={chartRef} />)
}
export default BarChart
interface BarChart {
    labelData: any
    valueData: any
    headingData: any
    titleData: any
}