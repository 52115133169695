import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Avatar,
  alpha,
  Tooltip,
  CardActionArea,
  styled,
} from "@mui/material";
const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      margin: ${theme.spacing(2, 0, 1, -0.5)};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${theme.spacing(1)};
      padding: ${theme.spacing(0.5)};
      border-radius: 60px;
      height: ${theme.spacing(5.5)};
      width: ${theme.spacing(5.5)};
      background: ${theme.palette.mode === "dark"
      ? theme.colors.alpha.trueWhite[30]
      : alpha(theme.colors.alpha.black[100], 0.07)
    };
    
      img {
        background: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0.5)};
        display: block;
        border-radius: inherit;
        height: ${theme.spacing(4.5)};
        width: ${theme.spacing(4.5)};
      }
  `
);
const CardDashborad = ({ lable, name, time }: CardData) => {
  return (
    <Grid xs={12} sm={4} md={3} item>
      <Card
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {/* <CardContent> */}
        <Box sx={{ padding: 2, width: '100%' }} >
          <Typography variant="h5" textAlign={'center'} sx={{ width: '100%' }}>{lable}</Typography>
          <Typography variant="h5" textAlign={'center'} sx={{ width: '100%' }}>{time}</Typography>
          <Typography variant="subtitle2" textAlign={'center'} sx={{ width: '100%', marginTop: 1 }} >
            {name}
          </Typography>
        </Box>
        {/* </CardContent> */}
      </Card>
    </Grid >
  );
};
export default CardDashborad;

interface CardData {
  lable: any;
  name: number;
  time: any
}
