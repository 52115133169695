import { AxiosError } from "axios";
import { RuleTester } from "eslint";
import loginNavigate from "src/utils/loginnavigate";

export const parseAxiosError = async (e: AxiosError): Promise<string> => {
  const { response }: any = e;
  let message = "";
  if (response?.data?.errors?.length > 0) {
    message = response?.data?.errors[0];
    return message;
  }
  if (response?.data?.message) {
    await loginNavigate();
    message = response?.data?.message;
    return message;
  }
  // await loginNavigate();
  // let message: string = response?.data?.errors[0];
  // let message: string = response?.data?.message;

  // const res = response.config;
  // message = response?.data?.errors[0];
  return "unuthorized";
};
