import { useState, useEffect } from "react";

import {
  Tooltip,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  Modal,
  Button,
  Avatar,
  FormControl,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";

import { DELETE, GET } from "src/config/https";
import APIS from "src/config/api";
import getUserData from "src/utils/getLocalStorageData";
import { useNavigate } from "react-router";
import Color from "src/theme/colors";
import ReactPaginate from "react-paginate";
import "./CustomPagination.css";
import { toast } from "react-hot-toast";
import moment from "moment";
import SearchUi from "src/components/search";
import ButtonUI from "src/components/Button";
import FullScreenLoadder from "src/components/fullScreenLoadder";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #DCDCDC",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
  // broderColor: "#DCDCDC",
};
const sucessDelete = () => toast.success("Record deleted successfully");
const RecipeTable = () => {
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>(
    []
  );

  const [page, setPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);

  const [userData, setUserData] = useState<any>({});
  const [deleteUserId, setDeleteUserId] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [recipeAllCount, setRecipeAllCount] = useState(0);
  const [allRecipe, setAllRecipe] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const items = Array.from(Array(recipeAllCount).keys());
  const [pageDisaplyCount, setPageDisaplyCount] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState<any>("asc");

  useEffect(() => {
    if (allRecipe.length === 0 && lastPage > 0) {
      setLastPage(0);
      setPage(0);
      fetchAllRecipe(0);
    }
  }, [allRecipe]);

  const handleChangepageDisaplyCount = (event: any) => {
    setPageDisaplyCount(event.target.value);
    const newOffset = (0 * itemsPerPage) % items.length;

    setPage(0);
    setLastPage(0);
    setItemOffset(newOffset);
  };

  let itemsPerPage = 2;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = allRecipe.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(items.length / pageDisaplyCount);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setPage(event.selected);
    setLastPage(event.selected);
    setItemOffset(newOffset);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    let tempUserData = getUserData();

    setUserData(tempUserData);
    recipeCount();
  }, []);

  useEffect(() => {
    if (searchQuery.length > 1) {
      const newOffset = (0 * itemsPerPage) % items.length;

      setPage(0);
      setLastPage(0);
      setItemOffset(newOffset);
      fetchAllRecipe(0);
      return;
    }

    //  else fetchAllRecipe(page);
  }, [page, pageDisaplyCount, searchQuery]);

  useEffect(() => {
    if (page > 0) {
      fetchAllRecipe(page);
    }
    if (page === 0 && searchQuery.trim().length < 2) {
      fetchAllRecipe(page);
    }
  }, [page, searchQuery]);

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const fetchAllRecipe = async (pageNo?: number) => {
    let loading = searchQuery ? false : true;
    setIsLoading(loading);
    let tempUserData = getUserData();
    if (!tempUserData) return;
    let params = {
      PageNumber: pageNo ? pageNo.toString() : page.toString(),
      PageSize: pageDisaplyCount.toString(),
      SearchProperty: "Name",
      SearchValue: searchQuery,
      SearchOption: "contains",
    };

    await GET(APIS.GET_ALL_RECIPE, params)
      .then((res: any) => {
        if (res.isSuccess) {
          setAllRecipe(res.result);
        }
      })
      .catch((error) => {});
    setIsLoading(false);
  };

  const recipeCount = async () => {
    let tempUserData = getUserData();
    let params = {};
    await GET(APIS.GET_RECIPE_COUNT, params, tempUserData.jwtToken).then(
      (res: any) => {
        if (res.isSuccess) {
          let temp = res.result;
          setRecipeAllCount(temp);
          fetchAllRecipe(page);
        }
      }
    );
  };

  const theme = useTheme();

  const handleDeleteUserModal = (id: number) => {
    handleOpen();
    setDeleteUserId(id);
  };

  const handleDeleteUser = async () => {
    await DELETE(`${APIS.DELETE_RECIPE}/${deleteUserId}`, userData.jwtToken)
      .then((res: any) => {
        if (res.isSuccess) {
          recipeCount();
          sucessDelete();
        }
      })
      .catch((error) => {});

    handleClose();
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";

    setOrderBy(property);
    setOrder(newOrder);

    let sortedData = [];
    if (order === "asc") {
      sortedData = allRecipe.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      });
    } else {
      sortedData = allRecipe.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }

    setAllRecipe(sortedData);
  };

  const handleSortDate = (property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";

    setOrderBy(property);
    setOrder(newOrder);

    let sortedData = [];
    if (order === "asc") {
      function GFG_sortFunction(a, b) {
        let dateA = new Date(a.createdDate).getTime();
        let dateB = new Date(b.dacreatedDatete).getTime();
        return dateA > dateB ? 1 : -1;
      }
      sortedData = allRecipe.sort(GFG_sortFunction);
    } else {
      function GFG_sortFunction(a, b) {
        let dateA = new Date(a.createdDate).getTime();
        let dateB = new Date(b.dacreatedDatete).getTime();
        return dateA < dateB ? 1 : -1;
      }
      sortedData = allRecipe.sort(GFG_sortFunction);
    }
    setAllRecipe(sortedData);
  };
  const isNotFound = !allRecipe.length;
  return (
    <>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ padding: 2, paddingLeft: 2 }}
          justifyContent={"space-between"}
        >
          <Box sx={{ width: "50%" }}>
            <Typography variant="h3" component="h3" gutterBottom>
              Recipes
            </Typography>
          </Box>
          <Box
            sx={{
              width: "50%",

              marginRight: 1,
              justifyContent: "flex-end",
            }}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            <SearchUi
              handleSearch={(e) => handleSearch(e)}
              searchQuery={searchQuery}
              styles={{ marginRight: 20 }}
            />
            <ButtonUI
              variant="contained"
              label="Add Recipe"
              onClick={() => navigate("/management/addrecipe")}
            />
          </Box>
        </Box>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>
                  {/* <TableSortLabel
                    active={orderBy === "name"}
                    // active={true}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={() => handleSort("name")}
                  > */}
                  Name
                  {/* </TableSortLabel> */}
                </TableCell>
                <TableCell>
                  {/* <TableSortLabel
                    active={orderBy === "type"}
                    direction={orderBy === "type" ? order : "asc"}
                    onClick={() => handleSort("type")}
                  > */}
                  type
                  {/* </TableSortLabel> */}
                </TableCell>

                <TableCell>Created Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {allRecipe.map((recipeData: any) => {
                const dateTime = moment(recipeData.createdDate);
                return (
                  <TableRow hover key={recipeData.id}>
                    <TableCell sx={{ width: "8%" }}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        <Avatar
                          alt={recipeData.name[0]}
                          src={
                            recipeData.imagePath
                              ? recipeData.imagePath
                              : recipeData.name[0]
                          }
                        />
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ width: "20%" }}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {recipeData.name}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "15%" }}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {recipeData.type}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {dateTime.format("MM/DD/YYYY h:mm A")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="View Recipe" arrow>
                        <IconButton
                          sx={{
                            "&:hover": {
                              background: theme.colors.primary.lighter,
                            },
                            color: theme.palette.primary.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={() =>
                            navigate("/management/recipedetail", {
                              state: {
                                recipePage: true,
                                recipeData: recipeData,
                              },
                            })
                          }
                        >
                          <RemoveRedEyeTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Recipe" arrow>
                        <IconButton
                          sx={{
                            "&:hover": {
                              background: theme.colors.error.lighter,
                            },
                            color: theme.palette.error.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={() => handleDeleteUserModal(recipeData.id)}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {Boolean(searchQuery.length > 1) && isNotFound && !isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Typography variant="h6" paragraph>
                      Not found
                    </Typography>

                    <Typography variant="body2">
                      No results found for &nbsp;
                      <strong>&quot;{searchQuery}&quot;</strong>.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}
          </Table>{" "}
        </TableContainer>
        <Box
          p={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {Boolean(allRecipe.length) ? (
            <>
              <FormControl size="small" sx={{ marginRight: 2 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageDisaplyCount}
                  onChange={handleChangepageDisaplyCount}
                  defaultValue={10}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={pageDisaplyCount}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                containerClassName={"custom-pagination"}
                activeClassName={"active"}
                forcePage={page}
              />
            </>
          ) : null}
        </Box>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ marginBottom: 1, color: Color.black }}
          >
            Confirmation
          </Typography>
          <Divider />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginTop: 1 }}
          >
            Are you sure you want to delete the recipe?
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            sx={{ marginTop: 1 }}
          >
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDeleteUser}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <FullScreenLoadder open={isLoading} />
    </>
  );
};

export default RecipeTable;
