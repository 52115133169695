import TextField from "@material-ui/core/TextField";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
const DropDownUi = ({
  item,
  value,
  handleChange,
  styles,
  lable,
}: DropDownUiProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{lable}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={lable}
          onChange={(event) => handleChange(event)}
          style={styles}
          placeholder="select"
        >
          {item.map((option: any) => (
            <MenuItem key={option.value} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
export default DropDownUi;

interface DropDownUiProps {
  item: any;
  value?: any;
  handleChange: (event: any) => void;
  styles?: React.CSSProperties;
  lable?: string;
}
