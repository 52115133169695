import { Box, Grid, IconButton, Typography } from "@mui/material";
import Color from "src/theme/colors";
import FontStyles from "src/utils/font";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
const OtherAttribute = ({ data, name }: otherAttribute) => {
  return (
    // <Grid item xs={2} sm={2} md={3}>
    //   <Box
    //     sx={{
    //       flexGrow: 1,
    //       width: "110px",
    //       height: "32px",
    //     }}
    //     display="inline-flex"
    //     alignSelf={"center"}
    //     alignItems={"center"}
    //     justifyContent={"left"}
    //   >
    //     <Box
    //       display="inline-flex"
    //       flexDirection={"row"}
    //       justifyContent={"center"}
    //       alignItems={"center"}
    //       alignSelf={"center"}
    //       textAlign={"center"}
    //     >
    //       <IconButton sx={{ padding: 0 }}>
    // {data ? (
    //   <CheckBoxOutlinedIcon fontSize="small" />
    // ) : (
    //   <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
    // )}
    //       </IconButton>
    // <Typography
    //   style={{
    //     ...FontStyles.font12Prociono,
    //     color: Color.color4,
    //     paddingLeft: 5,
    //   }}
    // >
    //   {name}
    // </Typography>
    //     </Box>
    //   </Box>
    // </Grid>
    <Grid container item sm={6} md={12 / 2} xs={6}>
      <Grid xs={3}>
        <Typography
          alignItems={"center"}
          display={"flex"}
          justifyContent={"center"}
          sx={{ marginTop: "8px" }}
        >
          {data ? (
            <CheckBoxOutlinedIcon fontSize="small" />
          ) : (
            <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
          )}
        </Typography>
      </Grid>
      <Grid xs={9}>
        <Grid xs={12}>
          <Typography
            style={{
              ...FontStyles.font16Prociono,
              color: Color.color4,
              paddingLeft: 5,
            }}
          >
            {name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OtherAttribute;

interface otherAttribute {
  data: boolean;
  name: string;
}
