import { Alert, Snackbar } from "@mui/material";

const Toster = ({
  openTost,
  handleCloseTost,
  errorMeesage,
  sucessMess,
}: TosterProps) => {
  return (
    <Snackbar
      open={openTost}
      autoHideDuration={3000}
      onClose={handleCloseTost}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        severity={errorMeesage ? "info" : "success"}
        sx={{ width: "100%" }}
      >
        {errorMeesage ? errorMeesage : sucessMess}
      </Alert>
    </Snackbar>
  );
};
export default Toster;

interface TosterProps {
  openTost: boolean;
  handleCloseTost?: () => void;
  errorMeesage: string;
  sucessMess?: string;
}
