import { InputAdornment, TextField, Typography } from "@mui/material";

import Color from "src/theme/colors";
import IconTypeCheck from "./displayIcon";
import React, { useState } from "react";

const TextBox = ({
  id,
  name,
  label,
  type,
  margin,
  required,
  fullWidth = true,
  style,
  icon,
  placeholder,
  onChange,
  value: value,
  defaultValue,
  disabled = false,
  passwordVisibaleData,
  maxlength,
}: TextFiledUiProps) => {
  const [passwordVisible, setPasswordVisibale] = useState(true);

  return (
    <TextField
      sx={{ borderColor: Color.color1 }}
      variant="outlined"
      margin={margin}
      placeholder={placeholder}
      required={required}
      fullWidth={fullWidth}
      id={id}
      label={label}
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      // style={Styles.fontFamilyStyles && style}
      style={style}
      onChange={onChange}
      value={value}
      type={passwordVisibaleData && passwordVisible ? "password" : type}
      inputProps={{
        maxLength: maxlength ? 30 : undefined,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {IconTypeCheck(icon)}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Typography
              onClick={() => setPasswordVisibale(!passwordVisible)}
              alignItems={"center"}
              display={"flex"}
            >
              {passwordVisibaleData
                ? IconTypeCheck(
                    passwordVisible ? "PasswordHide" : "PasswordShow"
                  )
                : null}
            </Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default TextBox;

type TextFiledUiProps = {
  id?: string;
  name?: string;
  label?: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
  margin?: "dense" | "normal" | "none";
  required?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  icon?: string;
  placeholder: string;
  onChange?: any;
  value?: unknown;
  extraLableStyle?: any;
  passwordVisibaleData?: boolean;
  iconOnClick?: () => void;
  defaultValue?: unknown;
  disabled?: boolean;
  maxlength?: number;
};

const Styles = {
  fontFamilyStyles: {
    fontFamily: "Prociono",
    width: "100%",
  },
};
