import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Backdrop,
} from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import Resizer from "react-image-file-resizer";

//icons
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Footer from "src/components/Footer";
import Styles from "./styles";
import { GET, POST } from "src/config/https";
import { isEmpty, validateImageBase64 } from "src/utils/Validator";
import ValidationError from "src/components/ValidationError";
import ButtonUi from "src/components/Button";
import DropDownUi from "src/components/Dropdown";
import RichBoxUi from "src/components/richBox";
import CheckBoxUi from "src/components/MultiCheckBox";
import TextBoxUi from "src/components/TextBox";
import { validateFileType } from "src/utils/Constant";
import InputFiles from "src/components/inputFiles";
import APIS from "src/config/api";
import { useNavigate } from "react-router";
import getUserData from "src/utils/getLocalStorageData";
import { toast } from "react-hot-toast";
import { base64 } from "src/utils/imageBase64";
import FullScreenLoadder from "src/components/fullScreenLoadder";
import TextBox from "src/components/TextBox";
import ButtonUI from "src/components/Button";
const Added = () => toast.success("Record successfully added");

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      431,
      323,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

const AddRecipe = () => {
  const classes = useStyles();
  const [recipeName, setRecipeName] = useState("");
  const [category, setCategory] = useState("");
  const [stepsToMake, setStepstomake] = useState("");
  const [about, setAbout] = useState("");

  const [inputFields, setInputFields] = useState([
    { Name: "", Quantity: 1, Unit: "", krogerProductId: "", ConvertedUnit: "", ConvertedQuantity: "", krogerUnit: "", krogerQuantity: 0 },
  ]);
  const [dietAttribute, setDietAttribute] = useState<any>(DietAttribute);
  const [otherAttribute, setOtherAttribute] = useState(seasonalFeaturedData);
  const [file, setFile] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [calories, setCalories] = useState("");
  const [cookTime, setCookTime] = useState("");
  const [servingSize, setServingSize] = useState()

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [openFullLoader, setOpenFullLoader] = useState(false);
  const theme = useTheme();

  const navigate = useNavigate();

  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  let datas = {};

  const handele = () => { };
  useEffect(() => {
    if (isValidation) {
      validator();
    }
  }, [
    recipeName,
    category,
    inputFields,
    stepsToMake,
    dietAttribute,
    calories,
    cookTime,
    file,
    imageBase64,
    otherAttribute,
    isValidation,
  ]);

  useEffect(() => {
    setDietAttribute(DietAttribute);
    setOtherAttribute(seasonalFeaturedData);
  }, []);

  const saveRichBox = (data: any, name) => {
    if (name == "About") {
      setAbout(data);
    }
    if (name == "Desc") {
      setStepstomake(data);
    }
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleChangeInput = (index: number, event: any) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
  };

  const handleCheckboxChange = (event: any, key: number) => {
    let data = [...DietAttribute];
    data[key].value = !data[key].value;
    setDietAttribute(data);
  };

  const handleSesonalFeatured = (event: any, key: number) => {
    let data = [...seasonalFeaturedData];
    data[key].value = !data[key].value;
    setOtherAttribute(data);
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { Name: "", Quantity: 1, Unit: "", krogerProductId: "", ConvertedUnit: "", ConvertedQuantity: "", krogerUnit: "", krogerQuantity: 0 }]);
  };

  const handleFileChange = (event: any) => {
    setFile(event.target.value);
    let temp = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      // const base64: any = e.target.result;
      const file = event.target.files[0];
      const image: any = await resizeFile(file);
      setImageBase64(image);
    };
    reader.readAsDataURL(temp);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const showAddButton = (index) => {
    if (inputFields.length === 1) {
      return true;
    }
    if (inputFields.length - 1 === index) {
      return true;
    }
    return false;
  };

  const showRemoveButton = (index) => {
    if (inputFields.length === 1) {
      return false;
    }
    if (inputFields.length - 1 === index) {
      return false;
    }
    return true;
  };

  const addRecipe = async () => {
    setIsValidation(true);
    if (!validator()) return;
    let tempUserData = getUserData();
    if (!tempUserData) return;

    const dietAttributeData = dietAttribute.reduce((obj, x) => {
      obj[x.attribute] = x.value;
      return obj;
    }, {});

    const otherAttributeData: any = otherAttribute.reduce((obj, x) => {
      if (x.name == "Quick Preparation") {
        obj["QuickPreparation"] = x.value;
        return obj;
      }
      obj[x.name] = x.value;
      return obj;
    }, {});

    let newIngradientType = [];

    inputFields.map((item) => {
      const validateName = Boolean(item.Name.trim().length);
      // const validateUnit = Boolean(item.Unit.trim().length);
      if (validateName && item.Quantity > 0) {
        newIngradientType.push(item);
      }
    });
    const params = {
      name: recipeName,
      type: category,
      ingredients: newIngradientType,
      stepToMake: stepsToMake,
      dietAttributes: dietAttributeData,
      calories: calories,
      imagePath: imageBase64?.split(",")[1] || "",
      seasonal: otherAttributeData.Seasonal,
      featured: otherAttributeData.Featured,
      spicy: otherAttributeData.Spicy,
      quickPreparation: otherAttributeData.QuickPreparation,
      cookTime: cookTime,
      servingSize: servingSize,
      about: about,
    };

    setIsLoading(true);
    setIsDisable(true);
    setOpenFullLoader(true);
    await POST(APIS.ADD_RECIPE, params, tempUserData.jwtToken)
      .then(async (res: any) => {
        if (res.isSuccess === true) {
          navigate("/management/recipes", { replace: true });
          window.location.reload();
          setIsLoading(false);
          setIsDisable(false);
          setDietAttribute(DietAttribute);
          setOtherAttribute(seasonalFeaturedData);
          Added();
        } else {
          setIsLoading(false);
          setIsDisable(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsDisable(false);
        setOpenFullLoader(false);
      });
    setOpenFullLoader(false);
  };

  const renderAddRemoveIngradint = (index) => {
    return (
      <Grid
        item
        xs={1}
        sm={1}
        md={0.8}
        justifyContent={"center"}
        display={"flex"}
        alignItems={"center"}
      >
        {/* {showAddButton(index) ? (
          <ButtonUI
            variant="contained"
            color="primary"
            onClick={() => handleAddFields()}
            icon={<AddIcon />}
          />
        ) : null}
        {showRemoveButton(index) ? ( */}
        <ButtonUI
          variant="contained"
          color="primary"
          onClick={() => handleRemoveFields(index)}
          icon={<DeleteForeverIcon />}
        />
        {/* ) : null} */}
      </Grid>
    );
  };
  const renderAddIngradint = () => {
    return (
      <>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          justifyContent={"flex-end"}
          display={"flex"}
          alignItems={"center"}
        >
          <ButtonUI
            variant="contained"
            color="primary"
            onClick={() => handleAddFields()}
            icon={<AddIcon />}
            styles={{ marginRight: 1.7, marginTop: 5 }}
          />
        </Grid>
      </>
    );
  };

  const validator = () => {
    const errors: Record<string, string> = {};

    if (isEmpty(recipeName.trim())) {
      errors.recipeName = "Please enter a recipe name";
    }
    if (isEmpty(stepsToMake)) {
      errors.stepsToMake = "Please enter steps";
    }
    if (isEmpty(imageBase64)) {
      errors.file = "Please choose Image ";
    }
    if (imageBase64 && !validateImageBase64(imageBase64)) {
      errors.file = "Please choose Image only png,jpg,jpeg ";
    }
    if (isEmpty(servingSize)) {
      errors.ServingSize = "Please enter the serving size";
    }

    const validateName = Boolean(inputFields[0].Name.trim().length);
    const validateUnit = Boolean(inputFields[0].Unit.trim().length);

    if (!validateName) {
      errors.inputFields = "Please enter ingredients name";
    }
    if (validateName && !validateUnit) {
      errors.inputFields = "Please enter ingredients unit";
    }
    if (validateName && validateUnit && inputFields[0].Quantity <= 0) {
      errors.inputFields = "Please enter ingredients quantity";
    }

    // if (
    //   inputFields &&
    //   inputFields.some(
    //     (x) => x.Name.length === 0 && x.Unit.length === 0 && x.Quantity < 1
    //   )
    // ) {
    //   inputFieldsData = 1;
    //   errors.inputFields = "Please enter ingredients detail";
    // }
    // if (inputFieldsData === 1 && inputFields.some((x) => x.Unit.length === 0)) {
    //   inputFieldsData = 1;
    //   errors.inputFields = "Please enter ingredients detail ";
    // }
    // if (inputFieldsData === 1 && inputFields.some((x) => x.Quantity < 1)) {
    //   inputFieldsData = 1;
    //   errors.inputFields = "Please enter ingredients detail";
    // }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCloseFullScreenLoader = () => {
    setOpenFullLoader(false);
  };
  const handleOpenFullScreenLoader = () => {
    setOpenFullLoader(true);
  };

  return (
    <>
      <Helmet>
        <title>Add Recipe</title>
      </Helmet>

      <Container maxWidth="lg" style={{ marginTop: isMatch ? 10 : 15 }}>
        <Grid container direction="row" justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Add Recipe" style={{ textAlign: "center" }} />
              <Divider />
              <CardContent>
                <Box style={Styles.mainContiner}>
                  <Grid
                    style={{
                      width: "95%",
                    }}
                  >
                    <Box style={Styles.conatinerComman}>
                      {" "}
                      <Typography variant="h4" style={{ marginBottom: 10 }}>
                        Name*
                      </Typography>
                      <TextBoxUi
                        id="Name"
                        placeholder="Name"
                        // label="Name"
                        name="Name"
                        style={{ width: "100%" }}
                        onChange={(e: any) => setRecipeName(e.target.value)}
                      />
                      <ValidationError formErrors={formErrors.recipeName} />
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4" style={{ marginBottom: 10 }}>
                        Select Recipe Type
                      </Typography>
                      <DropDownUi
                        handleChange={(event) => {
                          handleChange(event);
                        }}
                        value={category}
                        item={categoryList}
                        styles={Styles.width100}
                      // lable={"Select Recipe Type"}
                      />

                      <ValidationError formErrors={formErrors.category} />
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <form onSubmit={handleSubmit}>
                        <Box
                          style={{
                            width: "100%",
                          }}
                        >
                          <Typography variant="h4" style={{ marginBottom: 20 }}>
                            Ingredients*
                          </Typography>
                          {inputFields.map((inputField, index) => (
                            <Grid
                              sx={{
                                width: "50%",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                marginBottom:
                                  inputFields.length - 1 == index ? 0 : 2,
                              }}
                              container
                              spacing={{ xs: 1, md: 0.1 }}
                              columns={{ xs: 2, sm: 4, md: 12 }}
                              key={index}
                            >
                              <Grid item xs={2} sm={4} md={2.2}>
                                <TextBoxUi
                                  id="Name"
                                  placeholder="Name"
                                  label="Name"
                                  name="Name"
                                  style={{ width: "100%" }}
                                  value={inputField.Name}
                                  onChange={(event) =>
                                    handleChangeInput(index, event)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} sm={2} md={1.2}>
                                <TextBoxUi
                                  id="Unit"
                                  placeholder="Display Unit"
                                  label="Display Unit"
                                  name="Unit"
                                  style={{ width: "100%" }}
                                  value={inputField.Unit}
                                  onChange={(event) =>
                                    handleChangeInput(index, event)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} sm={2} md={1.2}>
                                <TextBoxUi
                                  type="number"
                                  id="Quantity"
                                  placeholder="Display Quantity"
                                  label="Display Quantity"
                                  name="Quantity"
                                  style={{ width: "100%" }}
                                  value={inputField.Quantity}
                                  onChange={(event) =>
                                    handleChangeInput(index, event)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} sm={2} md={1.2}>
                                <TextBoxUi
                                  // type="number"
                                  id="Quantity"
                                  placeholder="Converted Unit"
                                  label="Converted Unit"
                                  name="ConvertedUnit"
                                  style={{ width: "100%" }}
                                  value={inputField.ConvertedUnit}
                                  onChange={(event) =>
                                    handleChangeInput(index, event)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} sm={2} md={1.2}>
                                <TextBoxUi
                                  type="number"
                                  id="Quantity"
                                  placeholder="Converted Quantity"
                                  label="Converted Quantity"
                                  name="ConvertedQuantity"
                                  style={{ width: "100%" }}
                                  value={inputField.ConvertedQuantity}
                                  onChange={(event) =>
                                    handleChangeInput(index, event)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} sm={2} md={1.2}>
                                <TextBoxUi
                                  type="text"
                                  id="krogerProductId"
                                  placeholder="Kroger Item Id"
                                  label="Kroger Item Id"
                                  name="krogerProductId"
                                  style={{ width: "100%" }}
                                  value={inputField.krogerProductId}
                                  onChange={(event) =>
                                    handleChangeInput(index, event)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} sm={2} md={1.2}>
                                <TextBoxUi
                                  type="text"
                                  id="krogerUnit"
                                  placeholder="Kroger Unit"
                                  label="Kroger Unit"
                                  name="krogerUnit"
                                  style={{ width: "100%" }}
                                  value={inputField.krogerUnit}
                                  onChange={(event) =>
                                    handleChangeInput(index, event)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} sm={2} md={1.2}>
                                <TextBoxUi
                                  type="number"
                                  id="krogerQuantity"
                                  placeholder="Kroger Quantity"
                                  label="Kroger Quantity"
                                  name="krogerQuantity"
                                  style={{ width: "100%" }}
                                  value={inputField.krogerQuantity}
                                  onChange={(event) =>
                                    handleChangeInput(index, event)
                                  }
                                />
                              </Grid>
                              {renderAddRemoveIngradint(index)}
                            </Grid>
                          ))}
                        </Box>
                        {renderAddIngradint()}
                        <ValidationError formErrors={formErrors.inputFields} />
                      </form>
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4" style={{ marginBottom: 10 }}>Serving Size</Typography>
                      <TextBoxUi
                        id="ServingSize"
                        // label="Calories"
                        type="number"
                        placeholder="ServingSize"
                        name="ServingSize"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setServingSize(e.target.value);
                        }}
                        defaultValue={servingSize}
                      />

                      <ValidationError
                        formErrors={formErrors.ServingSize}
                      />
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4" style={{ marginBottom: 10 }}>
                        Steps To Make*
                      </Typography>
                      <RichBoxUi
                        onChangeText={saveRichBox}
                        styles={Styles.richTextBox}
                        name={"Desc"}
                      />
                      <ValidationError formErrors={formErrors.stepsToMake} />
                    </Box>

                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4">Diet Attributes</Typography>
                      <CheckBoxUi
                        itemList={dietAttribute}
                        onChange={(e: any, index: number) => {
                          handleCheckboxChange(e, index);
                        }}
                      />
                      <ValidationError formErrors={formErrors.daitAttribute} />
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4" style={{ marginBottom: 10 }}>
                        Calories
                      </Typography>
                      <TextBoxUi
                        id="Calories"
                        // label="Calories"
                        placeholder="Calories"
                        name="Calories"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setCalories(e.target.value);
                        }}
                      />
                      <ValidationError formErrors={formErrors.calories} />
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4" style={{ marginBottom: 10 }}>
                        Recipe Image*
                      </Typography>
                      <InputFiles onChange={handleFileChange} />

                      <ValidationError formErrors={formErrors.file} />
                    </Box>

                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4">Other Attributes</Typography>

                      <CheckBoxUi
                        itemList={otherAttribute}
                        onChange={(e: any, index) =>
                          handleSesonalFeatured(e, index)
                        }
                      />
                      <ValidationError
                        formErrors={formErrors.seasonalFeatured}
                      />
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4" style={{ marginBottom: 10 }}>
                        Cook Time
                      </Typography>
                      <TextBoxUi
                        id="CookTime "
                        // label="Cook Time "
                        placeholder="Cook Time "
                        name="CookTime "
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setCookTime(e.target.value);
                        }}
                      />
                      <ValidationError formErrors={formErrors.calories} />
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <Typography variant="h4" style={{ marginBottom: 10 }}>
                        About
                      </Typography>
                      <RichBoxUi
                        onChangeText={saveRichBox}
                        styles={Styles.richTextBox}
                        name={"About"}
                      />
                      <ValidationError formErrors={formErrors.about} />
                    </Box>
                    <Box style={Styles.conatinerComman}>
                      <ButtonUi
                        variant="contained"
                        styles={Styles.width100}
                        label="Add Recipe"
                        onClick={addRecipe}
                        isDisable={isDisable}
                      // isLoading={isLoading}
                      />
                    </Box>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
      <FullScreenLoadder open={openFullLoader} />
    </>
  );
};
export default AddRecipe;

const DietAttribute = [
  {
    id: 1,
    name: "Vegetarian",
    value: false,
    attribute: "vegetarian",
  },
  {
    id: 2,
    name: "Nut-Free",
    value: false,
    attribute: "nutFree",
  },
  {
    id: 3,
    name: "Dairy-Free",
    value: false,
    attribute: "dairyFree",
  },
  {
    id: 4,
    name: "Soy-Free",
    value: false,
    attribute: "soyFree",
  },
  {
    id: 5,
    name: "Egg-Free",
    value: false,
    attribute: "eggFree",
  },
  {
    id: 6,
    name: "Wheat-Fee",
    value: false,
    attribute: "wheatFree",
  },
  {
    id: 7,
    name: "Sesame-free",
    value: false,
    attribute: "sesameFree",
  },
];

const seasonalFeaturedData = [
  {
    id: 1,
    name: "Seasonal",
    value: false,
  },
  {
    id: 2,
    name: "Featured",
    value: false,
  },
  {
    id: 3,
    name: "Spicy",
    value: false,
  },
  {
    id: 4,
    name: "Quick Preparation",
    value: false,
  },
];

const categoryList = [
  {
    value: 1,
    label: "Dinner",
  },
  {
    value: 2,
    label: "Lunch",
  },
  {
    value: 3,
    label: "Breakfast",
  },
];
const useStyles = makeStyles({
  textarea: {
    resize: "both",
  },
});
