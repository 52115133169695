import "./global.css"
import { useRoutes } from "react-router-dom";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import getUserData from "./utils/getLocalStorageData";
import routers from "./routers"; //auth
import router from "src/router"; //main
import Compose from "./utils/Compose";
import { ProvideGlobalState } from "./contexts/GlobalContext";

function App() {
  const content = useRoutes(router);
  const contents = useRoutes(routers);
  const userData = getUserData();

  return (
    <ThemeProvider>
      <Compose components={[ProvideGlobalState]}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {userData ? content : contents}
        </LocalizationProvider>
      </Compose>
    </ThemeProvider>
  );
}
export default App;
