import { Box } from "@mui/material";
import { CKEditor } from "ckeditor4-react";

const RichBoxUi = ({ onChangeText, styles, name, data }: RichBoxUiProps) => {
  const config = {
    removePlugins: "image,about,source",
    extraPlugins: "font",
  };
  return (
    <Box>
      <CKEditor
        // initData="This is a CKEditor 4 WYSIWYG editor instance created by ️⚛️ React."
        // data={content}
        initData={data}
        config={config}
        onChange={(event) => {
          onChangeText(event.editor.getData(), name);
          // onChangeText(event.editor.getData());
        }}
      />
    </Box>
  );
};
export default RichBoxUi;

interface RichBoxUiProps {
  onChangeText: (event: any, name: string) => void;
  styles?: React.CSSProperties;
  name: string;
  data?: any;
}
