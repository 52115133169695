import { Typography } from "@mui/material";
import FontStyles from "src/utils/font";

const HeaderText = ({ name }: HeaderTextProops) => {
  return (
    <Typography
      sx={{ ...FontStyles.font20Prociono, marginTop: 1, marginBottom: 1 }}
      textAlign={"center"}
    >
      {name}
    </Typography>
  );
};
export default HeaderText;

interface HeaderTextProops {
  name: string;
}
