import { useState, useEffect } from "react";

import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  Modal,
  Button,
  TableSortLabel,
  Paper,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import RemoveRedEyeTwoToneIcon from "@mui/icons-material/RemoveRedEyeTwoTone";

import { DELETE, GET } from "src/config/https";
import APIS from "src/config/api";
import getUserData from "src/utils/getLocalStorageData";
import { useNavigate } from "react-router";
import Color from "src/theme/colors";

import ReactPaginate from "react-paginate";
import Toster from "src/components/tost";
import moment from "moment";
import { toast } from "react-hot-toast";
import SearchUi from "src/components/search";
import loginNavigate from "src/utils/loginnavigate";
import FullScreenLoadder from "src/components/fullScreenLoadder";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #DCDCDC",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
  // broderColor: "#DCDCDC",
};
const sucessDelete = () => toast.success("Record deleted successfully");

const RecentOrdersTable = () => {
  const [page, setPage] = useState<number>(0);
  const [lastPage, setLastPage] = useState<number>(0);
  const [userData, setUserData] = useState<any>({});
  const [deleteUserId, setDeleteUserId] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isCheckedSubcription, setIsCheckedSubcription] = useState(false);
  const [isCheckedNotSubcription, setIsCheckedNotSubcription] = useState(false);

  const [allUserCount, setAllUserCount] = useState(0);
  const [openTost, setOpenTost] = useState(true);

  const [itemOffset, setItemOffset] = useState(0);
  const items = Array.from(Array(allUserCount).keys());
  const [pageDisaplyCount, setPageDisaplyCount] = useState(10);
  const [allUser, setAllUser] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState<any>("asc");
  const [isLoading, setIsLoading] = useState(false);

  const handleChangepageDisaplyCount = (event: any) => {
    setPageDisaplyCount(event.target.value);
    const newOffset = (0 * itemsPerPage) % items.length;

    setPage(0);
    setLastPage(0);
    setItemOffset(newOffset);
  };

  let itemsPerPage = pageDisaplyCount;

  const pageCount = Math.ceil(items.length / pageDisaplyCount);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setPage(event.selected);
    setLastPage(event.selected);
    setItemOffset(newOffset);
  };



  useEffect(() => {
    if (allUser.length === 0 && lastPage > 0) {
      setLastPage(0);
      setPage(0);
      getAllUser(0);
    }
  }, [allUser]);

  useEffect(() => {
    getAllUser(0);
    getAllUserCount()
  }, [isCheckedSubcription, isCheckedNotSubcription])

  const handleCheckboxChange = (event, key: string) => {
    if (key === 'IsSubcription') {
      if (isCheckedNotSubcription) {
        setIsCheckedNotSubcription(false)
      }
      setIsCheckedSubcription(event.target.checked);
    }
    if (key === 'IsNotSubcription') {
      if (isCheckedSubcription) {
        setIsCheckedSubcription(false);
      }
      setIsCheckedNotSubcription(event.target.checked)
    }
  };

  useEffect(() => {
    if (page > 0) {
      getAllUser(page);
    }
    if (page === 0 && searchQuery.trim().length < 2) {
      getAllUser(page);
    }
  }, [page, searchQuery]);

  useEffect(() => {
    let tempUserData = getUserData();
    setUserData(tempUserData);
    getAllUser();
    getAllUserCount();
  }, []);

  useEffect(() => {
    if (searchQuery.length > 2) {
      const newOffset = (0 * itemsPerPage) % items.length;

      setPage(0);
      setLastPage(0);
      setItemOffset(newOffset);
      getAllUser();
      return;
    } else getAllUser();
  }, [page, pageDisaplyCount, searchQuery]);

  const getAllUserCount = async () => {
    const param: any = {
      IsSubscribed: isSubcriptionData(),
    };
    const tempUserData = await getUserData();
    if (!tempUserData) return;
    await GET(APIS.GET_USER_COUNT, param, tempUserData.jwtToken).then(
      (res: any) => {
        if (res.isSuccess) {
          setAllUserCount(res.result);
        }
      }
    );
  };

  //search
  const filteredData = allUser.filter((row: any) =>
    row.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearch = (event: any) => {
    setSearchQuery(event.target.value);
  };



  const getAllUser = async (pageNo?: number) => {
    let loading = searchQuery ? false : true;
    setIsLoading(loading);
    let tempUserData = await getUserData();
    if (!tempUserData) return;
    let params: any = {
      IsSubscribed: isSubcriptionData(),
      IsDescending: true,
      SortProperty: "createdDate",
      PageNumber: pageNo ? pageNo.toString() : page.toString(),
      PageSize: pageDisaplyCount.toString(),
      SearchProperty: "Name",
      SearchValue: searchQuery,
      SearchOption: "contains",
    };

    try {
      await GET(APIS.GET_USER, params, tempUserData.jwtToken)
        .then((res: any) => {
          if (res.isSuccess) {
            setAllUser(res.result);
          }
        })
        .catch((error: any) => {
          setOpenTost(true);
        });
    } catch { }
    setIsLoading(false);
  };

  const theme = useTheme();

  const handleDeleteUserModal = (id: number) => {
    handleOpen();
    setDeleteUserId(id);
  };

  const handleDeleteUser = async () => {
    if (!userData) return;
    await DELETE(`${APIS.DELETE_USER}/${deleteUserId}`, userData.jwtToken)
      .then((res) => {
        getAllUser();
        getAllUserCount();
        sucessDelete();
      })
      .catch((error) => { });

    handleClose();
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";
    setOrderBy(property);
    setOrder(newOrder);
    let sortedData = [];
    if (order === "asc") {
      sortedData = allUser.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      });
    } else {
      sortedData = allUser.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // Convert to uppercase for case-insensitive sorting
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    }

    setAllUser(sortedData);
  };

  const handleSortDate = (property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";

    setOrderBy(property);
    setOrder(newOrder);

    let sortedData = [];
    if (order === "asc") {
      function GFG_sortFunction(a, b) {
        let dateA = new Date(a.createdDate).getTime();
        let dateB = new Date(b.dacreatedDatete).getTime();
        return dateA > dateB ? 1 : -1;
      }
      sortedData = allUser.sort(GFG_sortFunction);
    } else {
      function GFG_sortFunction(a, b) {
        let dateA = new Date(a.createdDate).getTime();
        let dateB = new Date(b.dacreatedDatete).getTime();
        return dateA < dateB ? 1 : -1;
      }
      sortedData = allUser.sort(GFG_sortFunction);
    }
    setAllUser(sortedData);
  };

  const isNotFound = !allUser.length;

  const isSubcriptionData = () => {
    if (isCheckedSubcription) {
      return true
    }
    if (isCheckedNotSubcription) {
      return false
    }
    return
  }


  const getuserSubscriptions = (userSubscription) => {
    if (userSubscription.length > 0) {
      const data = userSubscription.find((item) => {
        return item.isActive
      })
      return data
    }

    return null
  }

  return (
    <>
      <Card>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ padding: 2, paddingLeft: 2 }}
        >
          <Box sx={{ width: "20%" }}>
            <Typography variant="h3" component="h3" gutterBottom>
              Users
            </Typography>
          </Box>
          <Box style={{ width: '50%' }}>
            <FormGroup row>
              <FormControlLabel control={<Checkbox defaultChecked checked={isCheckedSubcription}
                onChange={(event) => { handleCheckboxChange(event, "IsSubcription") }} />} label="Subscribed" />
              <FormControlLabel control={<Checkbox checked={isCheckedNotSubcription}
                onChange={(event) => { handleCheckboxChange(event, "IsNotSubcription") }} />} label="Not Subscribed" />
            </FormGroup></Box>
          <Box
            sx={{
              width: "30%",

              marginRight: 1,
              justifyContent: "flex-end",
            }}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
          >
            {/* <ButtonUI variant={"contained"} label="Add User" /> */}
            <SearchUi
              handleSearch={(e) => handleSearch(e)}
              searchQuery={searchQuery}
            />
          </Box>

        </Box>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {/* <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={() => handleSort("name")}
                  > */}
                  Name
                  {/* </TableSortLabel> */}
                </TableCell>
                <TableCell>
                  {/* <TableSortLabel
                    active={orderBy === "email"}
                    direction={orderBy === "email" ? order : "asc"}
                    onClick={() => handleSort("email")}
                  > */}
                  Email
                  {/* </TableSortLabel> */}
                </TableCell>
                {/* <TableCell>Mobile Number</TableCell> */}
                <TableCell align="center" sx={{ width: '15%' }}>Subcription Start Date</TableCell>
                <TableCell align="center" sx={{ width: '15%' }}>Subcription End Date</TableCell>
                <TableCell>Last Login</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allUser.map((userDetailsRow: any) => {
                const dateTime = moment(userDetailsRow.createdDate);
                const userSubscriptionsData = getuserSubscriptions(userDetailsRow && userDetailsRow?.userSubscriptions)
                return (
                  <TableRow hover key={userDetailsRow.id}>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {userDetailsRow.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {userDetailsRow.email}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {userDetailsRow.mobileNumber}
                      </Typography>
                    </TableCell> */}
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {userSubscriptionsData ? moment(userSubscriptionsData?.startDate).format("DD/MM/YYYY h:mm A") : ''}
                        {/* {userDetailsRow?.userSubscriptions[0]?.startDate
                          ? moment(userDetailsRow?.userSubscriptions[0]?.startDate).format("DD/MM/YYYY h:mm A") : ""} */}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {/* {userDetailsRow?.userSubscriptions[0]?.endDate
                          ? moment(userDetailsRow?.userSubscriptions[0]?.endDate).format("DD/MM/YYYY h:mm A") : ""} */}
                        {userSubscriptionsData ? moment(userSubscriptionsData?.endDate).format("DD/MM/YYYY h:mm A") : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {userDetailsRow?.lastLogOn ? moment(userDetailsRow.lastLogOn).format("DD/MM/YYYY h:mm A") : ""}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "15%" }}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {dateTime.format("DD/MM/YYYY h:mm A")}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title="View User" arrow>
                        <IconButton
                          sx={{
                            "&:hover": {
                              background: theme.colors.primary.lighter,
                            },
                            color: theme.palette.primary.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={() =>
                            navigate("/management/userdetail", {
                              state: userDetailsRow.id,
                            })
                          }
                        >
                          <RemoveRedEyeTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete User" arrow>
                        <IconButton
                          sx={{
                            "&:hover": {
                              background: theme.colors.error.lighter,
                            },
                            color: theme.palette.error.main,
                          }}
                          color="inherit"
                          size="small"
                          onClick={() =>
                            handleDeleteUserModal(userDetailsRow.id)
                          }
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {Boolean(searchQuery.length > 2) && isNotFound && !isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Typography variant="h6" paragraph>
                      Not found
                    </Typography>

                    <Typography variant="body2">
                      No results found for &nbsp;
                      <strong>&quot;{searchQuery}&quot;</strong>.
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>

        <Box
          p={2}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {Boolean(allUser.length) ? (
            <>
              <FormControl size="small" sx={{ marginRight: 2 }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pageDisaplyCount}
                  onChange={handleChangepageDisaplyCount}
                  defaultValue={10}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={pageDisaplyCount}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                containerClassName={"custom-pagination"}
                activeClassName={"active"}
                forcePage={page}
              />
            </>
          ) : null}
        </Box>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{ marginBottom: 1, color: Color.black }}
          >
            Confirmation
          </Typography>
          <Divider />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginTop: 1 }}
          >
            Are you sure you want to delete the account?
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            sx={{ marginTop: 1 }}
          >
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDeleteUser}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <Toster openTost={openTost} errorMeesage={"cdndcskcdkns"} />
      <FullScreenLoadder open={isLoading} />
    </>
  );
};

export default RecentOrdersTable;
