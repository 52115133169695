import { Helmet } from "react-helmet-async";
import { Box, Card, Container, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import Footer from "src/components/Footer";

import Wallets from "./Wallets";
import { useEffect, useState } from "react";
import PieChart from "src/components/pieChart";
import getUserData from "src/utils/getLocalStorageData";
import { GET } from "src/config/https";
import APIS from "src/config/api";
import BarChart from "src/components/barChart";
import FontStyles from "src/utils/font";
import Color from "src/theme/colors";

import FullScreenLoadder from "src/components/fullScreenLoadder";


function DashboardOverview() {
  const [metrixData, setMatrixData] = useState<any>({});
  const [currentWeekRecipeLable, setCurrentWeekRecipeLable] = useState([]);
  const [currentWeekRecipeCount, setCurrentWeekRecipeCount] = useState([]);
  const [currentMonthRecipeLable, setCurrentMonthRecipeLable] = useState([]);
  const [currentMonthRecipeCount, setCurrentMonthRecipeCount] = useState([]);
  const [filterTime, setFilterTime] = useState(0)
  const [loadder, setLoadder] = useState(false)

  useEffect(() => {
    fetchMetrixData();
  }, []);

  useEffect(() => {
    let newArray = [];
    let newArrayCount = [];
    let newArrayMonth = []
    let newArrayMonthCount = []
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    if (metrixData) {
      metrixData?.most10UsedRecipesInCurrentWeek &&
        metrixData?.most10UsedRecipesInCurrentWeek.map((x) => {
          let data = capitalizeFirstLetter(x?.recipeResponse?.name)
          newArray.push(data);
          return;
        });
      metrixData?.most10UsedRecipesInCurrentWeek &&
        metrixData?.most10UsedRecipesInCurrentWeek.map((x) => {
          newArrayCount.push(x?.count);
          return;
        });
      metrixData?.most10UsedRecipesInCurrentMonth &&
        metrixData?.most10UsedRecipesInCurrentMonth.map((x) => {
          let data = capitalizeFirstLetter(x?.recipeResponse?.name)
          newArrayMonth.push(data);
          return;
        });
      metrixData?.most10UsedRecipesInCurrentMonth &&
        metrixData?.most10UsedRecipesInCurrentMonth.map((x) => {
          newArrayMonthCount.push(x?.count);
          return;
        });
    }
    setCurrentWeekRecipeCount(newArrayCount);
    setCurrentWeekRecipeLable(newArray);
    setCurrentMonthRecipeLable(newArrayMonth)
    setCurrentMonthRecipeCount(newArrayMonthCount)
  }, [metrixData]);



  const fetchMetrixData = async () => {
    setCurrentWeekRecipeCount([])
    setCurrentWeekRecipeLable([])
    setLoadder(true)
    let tempUserData = getUserData();
    if (!tempUserData) return;
    let param = {};
    let resp = await GET(APIS.GET_MATRIX, param, tempUserData.jwtToken).then(
      (res: any) => {
        if (res.isSuccess) {
          // setMatrixData(JSON.parse(res.result));
          setMatrixData(res.result);
        }
      }
    ).finally(() => {
      setLoadder(false)
    });
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setFilterTime(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Overview</title>
      </Helmet>
      {/* <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper> */}
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Box sx={{ borderBottom: 1, marginTop: 10, borderColor: 'divider', width: '90%' }}>
            <Tabs value={filterTime} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="All" style={{ ...FontStyles.font20PlayfairDisplaySC, }} />
              <Tab label="Week" style={{ ...FontStyles.font20PlayfairDisplaySC, }} />
              <Tab label="Month" style={{ ...FontStyles.font20PlayfairDisplaySC }} />
            </Tabs>
          </Box>
          <div style={{ width: "90%", marginTop: 10, padding: 2 }}>
            <Grid item lg={12} xs={12}>
              <Wallets metrixData={metrixData} tabId={filterTime} />
            </Grid>
          </div>

          <Typography sx={{ ...FontStyles.font20PlayfairDisplaySC, color: Color.color5, width: '90%', marginTop: 2 }}>Recipes</Typography>
          <Divider style={{ marginTop: 1, marginBottom: 15, width: '90%' }} />
          {filterTime === 1 || filterTime === 0 ? <Card sx={{ width: "90%", padding: 2, }}>
            {currentWeekRecipeLable && currentWeekRecipeCount ? <BarChart labelData={currentWeekRecipeLable} valueData={currentWeekRecipeCount} headingData={`Most ${currentWeekRecipeCount?.length} Used Recipes In Current Week`} titleData={'My Week Dataset'} /> : null}
          </Card> : null}
          {filterTime === 2 || filterTime === 0 ? <Card sx={{ width: "90%", marginTop: 2, padding: 2 }}>
            {currentMonthRecipeLable && currentMonthRecipeCount ? <BarChart labelData={currentMonthRecipeLable} valueData={currentMonthRecipeCount} headingData={`Most ${currentMonthRecipeLable?.length} Used Recipes In Current Month`} titleData={'My Month Dataset'} /> : null}
          </Card> : null}

          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              justifyContent: "space-between",
              alignSelf: "center",
              marginTop: 30,
            }}
          >
            // {filterTime === 1 || filterTime === 0 ? */}
          <Card
            sx={{
              width: 0,
              // padding: 2,
              height: 0,


            }}
          >
            <PieChart
              dataLable={currentWeekRecipeLable}
              dataValue={currentWeekRecipeCount}
              headingData={'Most 5 Used Recipes In Current Week'}
            />
          </Card>

          {/* {filterTime === 2 || filterTime === 0 ? <Card
            sx={{
              width: "48%",
              padding: 2,
              height: 500,
              justifyContent: "center",
              display: "flex",
            }}
          > */}
          {/* <PieChart
              dataLable={currentMonthRecipeLable}
              dataValue={currentMonthRecipeCount}
              headingData={'Most 10 Used Recipes In Current Month'}
            /> */}
          {/* </Card> : null} */}
          {/* </div> */}

          {/* <Grid item xs={12}>
            <AccountBalance />
          </Grid> */}

          {/* <Grid item lg={4} xs={12}>
            <AccountSecurity />
          </Grid>
          <Grid item xs={12}>
            <WatchList />
          </Grid> */}
        </Grid>
      </Container >
      <FullScreenLoadder open={loadder} />
      <Footer />
    </>
  );
}

export default DashboardOverview;
