import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  Box,
  Card,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ButtonUI from "src/components/Button";
import TextBox from "src/components/TextBox";
import { isEmpty, isValidEmail } from "src/utils/Validator";
import { POST } from "src/config/https";
import APIS from "src/config/api";
import Toster from "src/components/tost";
import ValidationError from "src/components/ValidationError";
import { usePersistentState } from "src/contexts/GlobalContext";
import { toast } from "react-hot-toast";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  const [userData, setUserData] = useState<any>({});
  const [openTost, setOpenTost] = useState(false);
  const [errorMessage, setErrorMeassage] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [state, dispatch]: any = usePersistentState();
  const notify = () => toast.error(errorMessage);

  useEffect(() => {
    navigate("/login", { replace: true });
  }, []);

  useEffect(() => {
    if (isValidation) {
      validator();
    }
  }, [email, password, isValidation]);
  useEffect(() => {
    getUserData();
  }, []);

  const handleClickTostOpen = (error: string) => {
    setErrorMeassage(error);
    setOpenTost(true);
  };

  const handleCloseTost = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenTost(false);
  };

  const getUserData = async () => {
    let temp: any = await localStorage.getItem("sessiondata");
    setUserData(JSON.parse(temp));
  };

  const onChangeText = (key: string, e: any) => {
    switch (key) {
      case "email":
        setEmail(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
    }
  };

  const signIn = async () => {
    setIsValidation(true);
    if (!validator()) return;
    setLoading(true);

    const params = {
      email: email,
      password: password,
    };

    await POST(APIS.LOGIN, params)
      .then(async (res: any) => {
        if (res.isSuccess == true) {
          if (res.result.roles[0].name === "SuperAdmin") {
            await localStorage.setItem(
              "sessiondata",
              JSON.stringify({ ...res.result, isLogin: true })
            );
            dispatch({ sessionData: res.result, token: res.result.jwtToken });
            navigate("/dashboards/overView");

            setLoading(false);
          } else {
            setErrorMeassage("Invalid User");
            // setOpenTost(true);
            notify();
            setLoading(false);
          }
        } else {
          setLoading(false);
          handleClickTostOpen(res.errors[0]);
          notify();
        }
        // setUserData(res.result);
      })
      .catch((error) => {
        setLoading(false);
        setErrorMeassage(error);
        // handleClickTostOpen(error);
        notify();
      });
  };

  const validator = () => {
    const errors: Record<string, string> = {};
    if (isEmpty(email)) {
      errors.email = "Please enter a email";
    }

    if (isEmpty(password)) {
      errors.password = "Please enter a password";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F2F5F9",
          }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Card sx={{ padding: 2, width: "100%" }}>
            <Typography
              sx={{ fontSize: "28px", marginTop: 2 }}
              textAlign={"center"}
            >
              Sign In
            </Typography>
            <Typography
              sx={{ fontSize: "16px", marginTop: "5px" }}
              textAlign={"center"}
            >
              Please Sign In to Continue.
            </Typography>

            <TextBox
              style={{ marginTop: "30px", fontSize: "16px" }}
              placeholder={"Email"}
              label="Email"
              icon="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <ValidationError formErrors={formErrors.email} />
            <TextBox
              style={{ marginTop: 25, fontSize: "16px" }}
              placeholder={"Password"}
              label="Password"
              icon="LockIcon"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              passwordVisibaleData={true}
            />
            <ValidationError formErrors={formErrors.password} />

            <ButtonUI
              variant={"outlined"}
              label="Sign In"
              styles={{ marginTop: "50px", marginBottom: 25, height: "44px" }}
              fullWidth={true}
              onClick={signIn}
              isLoading={loading}
            />
          </Card>
        </Box>
      </Container>
      <Toster
        errorMeesage={errorMessage}
        handleCloseTost={handleCloseTost}
        openTost={openTost}
      />
    </>
  );
};
export default Login;
