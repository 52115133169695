import React, { useContext, createContext, useEffect, useState } from "react";

export const initialPersistentState = {
  token: "",
  sessionData: null,
  users: [],
  recipe: [],
};

const initialContext = [{ ...initialPersistentState }, () => {}];

export const persistentContext = createContext(initialContext);

export const usePersistentState = () => useContext(persistentContext);

export function ProvideGlobalState({ children }: any) {
  const persistentState = useProvideAppState();
  return (
    <persistentContext.Provider value={persistentState}>
      {children}
    </persistentContext.Provider>
  );
}

export const useProvideAppState = () => {
  const [localAppState, setLocalAppState] = useState(initialPersistentState);
  const [state, dispatch] = React.useReducer(
    (oldState: any, newValue: any) => ({ ...oldState, ...newValue }),
    localAppState
  );

  useEffect(() => {
    setLocalAppState(state);
  }, [setLocalAppState, state]);

  return [state, dispatch];
};
