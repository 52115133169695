import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

import FontStyles from "src/utils/font";
import Color from "src/theme/colors";
import { useLocation, useNavigate } from "react-router";
import regularcheckcricle from "../../../Assets/images/regularcheck-circle (2).png";
import VectorRound from "../../../Assets/images/VectorRound.png";
import APIS from "src/config/api";
import { GET, PUT } from "src/config/https";
import ButtonUI from "src/components/Button";
import getUserData from "src/utils/getLocalStorageData";
import TextBox from "src/components/TextBox";
import { toast } from "react-hot-toast";
import { isEmpty } from "src/utils/Validator";
import ValidationError from "src/components/ValidationError";
import Footer from "src/components/Footer";
import HeaderText from "src/components/headerText";
import FullScreenLoadder from "src/components/fullScreenLoadder";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FilePicker from "src/components/filePicker";
import manuserpng from "../../../Assets/images/man-user.png";
import ReminigDays from "src/components/reminingDays";
const updateSuccess = () => toast.success("Record updated successfully");

const UserDetail = () => {
  const location: any = useLocation();
  const [userProfile, setUserProfile] = useState<any>({});
  const [editDiet, setEditDiet] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [userData, setUserData] = useState<any>({});
  const [userValue, setUserValue] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [isValidation, setIsValidation] = useState(false);
  const [categoryItem, setCategoryItem] = useState(Category);
  const [imageBase64, setImageBase64] = useState("");
  const [subcriptionData, setSubcription] = useState<any>({})
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md" || "sm"));
  useEffect(() => {
    if (location.state) {
      getUserById();
    } else {
      navigate("/management/users");
    }
  }, []);

  useEffect(() => {
    fetachSubcription()
  }, [])

  useEffect(() => {
    let temp: any = [...categoryItem];
    userData.dietAttributes &&
      Object.keys(userData.dietAttributes).forEach((key) => {
        let findIndex = temp.findIndex((x: any) => x.label === key);

        if (findIndex !== -1) {
          temp[findIndex].check = Boolean(userData.dietAttributes[key]);
        }

      });
    setCategoryItem(temp);
  }, [userData]);

  useEffect(() => {
    if (isValidation) {
      validator();
    }
  }, [userValue, isValidation]);

  const getUserById = async () => {
    let tempUserData = getUserData();
    setIsLoading(true);
    if (!tempUserData) return;
    let params = {
      id: location.state,
    };
    await GET(APIS.GET_USER_BY_ID, params, tempUserData.jwtToken)
      .then((res: any) => {
        if (res) {
          // setUserProfile(res.result);
          // setUserValue(res.result);
          setUserData(res.result);
        }
      })
      .catch((error) => { });
    setIsLoading(false);
  };

  const handleFileChange = (event: any) => {
    // setFile(event.target.value);
    let temp = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const base64: any = e.target.result;
      setImageBase64(base64);
    };

    reader.readAsDataURL(temp);
  };

  const updateUserRequest = async () => {
    setIsValidation(true);
    if (!validator()) return;
    var object = categoryItem.reduce(
      (obj, item) => Object.assign(obj, { [item.label]: item.check }),
      {}
    );
    if (updateUser || editDiet) {
      setIsLoading(true);
      setIsDisable(true);
      setUpdateUser(false);
      let tempUserData = getUserData();
      if (!tempUserData) return;
      let params = {
        id: userData.id,
        name: userData.name,
        email: userData.email,
        mobileNumber: userData.mobileNumber,
        profilePicture: imageBase64?.split(",")[1] || "",
        dietAttributes: object,
      };
      await PUT(APIS.UPDATE_USER, params, tempUserData?.jwtToken)
        .then((res: any) => {
          if (res.isSuccess) {
            setUserProfile(res.result);
            setUserValue(res.result);
            navigate("/management/userdetail");
            setIsLoading(false);
            setIsDisable(false);
            updateSuccess();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsDisable(false);
        })
        .finally(() => {
          setUpdateUser(false);
          setEditDiet(false);
        });
    }
    // if (!updateUser) {
    //   setUpdateUser(true);
    // }
  };


  const textoxValueEvent = (e: any, key: string) => {
    let temp = { ...userData };

    if (key === "name") {
      temp.name = e.target.value;
    }
    if (key === "email") {
      temp.email = e.target.value;
    }
    if (key === "phone") {
      temp.mobileNumber = e.target.value;
    }
    setUserData(temp);
  };

  const validator = () => {
    const errors: Record<string, string> = {};
    if (isEmpty(userData.name)) {
      errors.name = "Please enter a name";
    }

    // if (isEmpty(userData.mobileNumber)) {
    //   errors.mobileNumber = "Please enter a phone number";
    // }
    // if (
    //   !isEmpty(userData.mobileNumber) &&
    //   !isNumeric(userData.mobileNumber)
    // ) {
    //   errors.phoneNumber = "Please enter a valid phone number";
    // }
    // if (userData.mobileNumber && userData.mobileNumber.length > 10) {
    //   errors.mobileNumber =
    //     "Your phone number should be of 10 digits. Please also ensure it does not start with 0.";
    // }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const setDay = (id: number) => {
    if (!editDiet) return;
    let data = categoryItem.map((item) => {
      if (item.id === id) {
        return { ...item, check: !item.check };
      }
      return item;
    });
    setCategoryItem(data);
  };



  const fetachSubcription = async () => {
    let tempUserData = await getUserData();
    if (!tempUserData) return;
    let param = {}
    await GET(`${APIS.GET_SUBCRIPTION_USER_BY_ID}id=${location.state}`, param, tempUserData.jwtToken).then((res: any) => {
      if (res.isSuccess) {
        setSubcription(res.result)
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>User Detail</title>
      </Helmet>

      <Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              height: "80%",
              width: "80%",
              top: "175px",
              boder: 1,
              borderRadius: 1,
              paddingRight: 3,
              paddingLeft: 3,
              paddingBottom: 3,
              marginTop: "20px",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Button
                sx={{ height: "40px", width: "30px" }}
                onClick={() => {
                  navigate("/management/users");
                }}
              >
                <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
              </Button>
              <Box>
                <HeaderText name="User Detail" />
              </Box>
              <Box sx={{ height: "40px", width: "30px" }}></Box>
            </Box>
            <Divider sx={{ marginBottom: 3 }} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box sx={{ backgroundColor: Color.color11, padding: 2 }}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    alignSelf={"center"}
                  >
                    {userData.profilePicturePath ? (
                      <img
                        src={userData.profilePicturePath}
                        alt="profile"
                        style={{ height: "250px", width: "100%" }}
                      />
                    ) : (
                      <img
                        alt="profile"
                        src={manuserpng}
                        style={{
                          height: "250px",
                          width: "100%",
                        }}
                      />
                    )}
                  </Box>
                  {updateUser ? (
                    <Box sx={{ marginTop: 1 }}>
                      <Typography
                        sx={{
                          marginTop: 2,
                          ...FontStyles.font16Prociono,
                          color: Color.color4,
                        }}
                      >
                        User Profile
                      </Typography>
                      <FilePicker onChange={handleFileChange} />

                      <ValidationError formErrors={formErrors.file} />
                    </Box>
                  ) : null}
                  <Typography
                    sx={{
                      ...FontStyles.font16Prociono,
                      color: Color.color4,
                      marginTop: "24px",
                    }}
                  >
                    Name
                  </Typography>
                  {updateUser ? (
                    <>
                      {userData.name && (
                        <TextBox
                          placeholder={"Name"}
                          defaultValue={userData?.name}
                          onChange={(e: any) => textoxValueEvent(e, "name")}
                          maxlength={30}
                        />
                      )}
                      <ValidationError formErrors={formErrors.name} />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        ...FontStyles.font16PlayfairDisplaySC,
                        color: Color.color10,
                        marginTop: "4px",
                      }}
                    >
                      {userData?.name}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      ...FontStyles.font16Prociono,
                      color: Color.color4,
                      marginTop: "24px",
                    }}
                  >
                    Email
                  </Typography>
                  {updateUser && userData ? (
                    <TextBox
                      placeholder={"Email"}
                      defaultValue={userData?.email}
                      onChange={(e: any) => textoxValueEvent(e, "email")}
                      disabled={true}
                    />
                  ) : (
                    <Typography
                      sx={{
                        ...FontStyles.font16PlayfairDisplaySC,
                        color: Color.color10,
                        marginTop: "4px",
                      }}
                    >
                      {userData?.email}
                    </Typography>
                  )}
                  {(!userData?.mobileNumber && updateUser) ||
                    userData?.mobileNumber ? (
                    <Typography
                      sx={{
                        marginTop: 2,
                        ...FontStyles.font16Prociono,
                        color: Color.color4,
                      }}
                    >
                      Phone
                    </Typography>
                  ) : null}
                  {updateUser ? (
                    <>
                      <TextBox
                        placeholder={"Phone"}
                        defaultValue={userData?.mobileNumber}
                        onChange={(e: any) => textoxValueEvent(e, "phone")}
                        type="number"
                      />
                      <ValidationError formErrors={formErrors.mobileNumber} />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        ...FontStyles.font16PlayfairDisplaySC,
                        color: Color.color10,
                        marginTop: "4px",
                      }}
                    >
                      {userData?.mobileNumber}
                    </Typography>
                  )}
                  {
                    userData?.country ? (
                      <Typography
                        sx={{
                          marginTop: 2,
                          ...FontStyles.font16Prociono,
                          color: Color.color4,
                        }}
                      >
                        Country
                      </Typography>
                    ) : null
                  }
                  <Typography
                    sx={{
                      ...FontStyles.font16PlayfairDisplaySC,
                      color: Color.color10,
                      marginTop: "4px",
                    }}
                  >
                    {userData?.country}
                  </Typography>
                  {
                    userData?.zipCode ? (
                      <Typography
                        sx={{
                          marginTop: 2,
                          ...FontStyles.font16Prociono,
                          color: Color.color4,
                        }}
                      >
                        Zip Code
                      </Typography>
                    ) : null
                  }
                  <Typography
                    sx={{
                      ...FontStyles.font16PlayfairDisplaySC,
                      color: Color.color10,
                      marginTop: "4px",
                    }}
                  >
                    {userData?.zipCode}
                  </Typography>
                  <Typography
                    sx={{
                      ...FontStyles.font16PlayfairDisplaySC,
                      color: Color.color10,
                      marginTop: "4px",
                    }}
                  >
                    {/* <ButtonUI
                      label={updateUser ? "Edit" : "Edit Details"}
                      variant="contained"
                      styles={{
                        width: "100%",
                        marginTop: 15,
                      }}
                      fontStyle={{
                        ...FontStyles.font14Prociono,
                      }}
                      onClick={() => {
                        if (updateUser) {
                          updateUserRequest();
                        } else {
                          setUpdateUser(true);
                        }
                      }}
                      // isLoading={isLoading}
                      // isDisable={isDisable}
                    /> */}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{}} display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    style={{
                      ...FontStyles.font24Prociono,
                      color: Color.color4,
                    }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    Diet Attributes
                  </Typography>
                  <div onClick={() => setEditDiet(!editDiet)}>
                    {" "}
                    <Typography
                      textAlign={"right"}
                      style={{
                        ...FontStyles.font16Prociono,
                        color: Color.color6,
                        cursor: "pointer",
                      }}
                      justifyContent={"center"}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      Edit
                    </Typography>
                  </div>
                </Box>

                <Box sx={{ border: 1, width: '100%', borderColor: Color.color17, }}></Box>
                <Grid container>

                  <Grid
                    container
                    spacing={{ xs: 1, md: 1, sm: 1 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    {categoryItem.map((x: any, index) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={4}
                          key={index}
                          sx={{ marginTop: 1 }}
                        >
                          <Box
                            sx={{
                              flexGrow: 1,
                              width: "110px",
                              height: "32px",
                            }}
                            display="inline-flex"
                            alignSelf={"center"}
                            alignItems={"center"}
                            justifyContent={"left"}
                          >
                            <Box
                              display="inline-flex"
                              flexDirection={"row"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              alignSelf={"center"}
                              textAlign={"center"}
                              paddingLeft={1}
                              onClick={() => setDay(x.id)}
                            >
                              <IconButton sx={{ padding: 0 }}>
                                {x.check ? (
                                  <img
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                    alt="check"
                                    src={regularcheckcricle}
                                  />
                                ) : (
                                  <img
                                    style={{
                                      width: "20px",
                                      height: "16.9px",
                                      paddingLeft: "2px",
                                      paddingRight: "2px",
                                    }}
                                    alt="check"
                                    src={VectorRound}
                                  />
                                )}
                              </IconButton>
                              <Typography
                                style={{
                                  ...FontStyles.font12Prociono,
                                  color: Color.color4,
                                  paddingLeft: 5,
                                }}
                              >
                                {x.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  {editDiet ? (
                    <div
                      style={{
                        marginTop: 5,

                        width: "100%",
                      }}
                    >
                      {/* <ButtonUI
                          label="Update"
                          variant="outlined"
                          isLoading={isLoading}
                          style={{
                            backgroundColor: Color.color1,
                            borderColor: Color.color1,
                            height: "30px",
                          }}
                          lableStyle={{
                            ...FontStyles.font16Prociono,
                            color: Color.color4,
                          }}
                          action={() => {
                            updateUserRequest();
                          }}
                        /> */}
                      <ButtonUI
                        // isLoading={isLoading}
                        label="Update"
                        variant="contained"
                        styles={{
                          whiteSpace: "nowrap",
                          marginBottom: 5,
                          width: "20%",
                          height: "35px",
                          marginTop: 15,
                        }}
                        onClick={() => {
                          updateUserRequest();
                        }}
                      />
                    </div>
                  ) : null}
                  <Box sx={{ border: 1, width: '100%', borderColor: Color.color17, }}></Box>
                  {/* <Typography sx={{ ...FontStyles.font24Prociono, color: Color.color4, width: '100%' }}>
                    Subscriptions Details
                  </Typography> */}
                  {/* {userData?.userSubscriptions?.length > 0 ? */}
                  {/* <Typography sx={{ ...FontStyles.font16Prociono, color: Color.color4, width: '100%' }}>
                    Status : {userData?.userSubscriptions && userData?.userSubscriptions[0]?.isActive ? "Active" : "DeActive"}
                  </Typography> */}
                  {/* : null} */}

                  {/* <Box
                    sx={{
                      width: "100%",
                      height: 1,
                      border: 1,
                      marginTop: 1,
                      marginBottom: 1,
                      color: Color.color17,
                    }}
                  ></Box> */}
                  <Typography
                    style={{
                      ...FontStyles.font24Prociono,
                      color: Color.color4,
                      width: "100%",
                      marginTop: 1,
                    }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    Subscription Details
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: 1,
                      border: 1,
                      // marginTop: 1,
                      color: Color.color17,
                    }}
                  ></Box>
                  <div style={{ width: "100%", marginTop: "10px" }}>
                    {subcriptionData?.remainingDaysForFreeTrial > 0 ? <Typography sx={{ ...FontStyles.font16Prociono, color: Color.color4, width: '100%' }}>
                      Status: Free Trail
                    </Typography> : <Typography sx={{ ...FontStyles.font16Prociono, color: Color.color4, width: '100%' }}>
                      Status: {userData?.userSubscriptions && userData?.userSubscriptions[0]?.isActive ? "Active" : "Not Active"}
                    </Typography>}
                    <ReminigDays
                      lable="Member Since:"
                      date={userData?.createdDate}
                    />
                    {!subcriptionData?.isFreeTrialEnded && subcriptionData?.remainingDaysForFreeTrial > 0 ? (
                      <Grid container spacing={0.5}>
                        <Grid item>
                          <Typography
                            variant="body1"
                            sx={{
                              ...FontStyles.font16Prociono,
                              color: Color.color4,
                            }}
                          >
                            Remaining Days For Free Trial:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body1"
                            sx={{
                              ...FontStyles.font16Prociono,
                              color: Color.color10,
                            }}
                          >
                            {subcriptionData?.remainingDaysForFreeTrial}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null}

                    {subcriptionData?.isFreeTrialEnded &&
                      subcriptionData?.startDate &&
                      (
                        <ReminigDays
                          lable="Start Date :"
                          date={subcriptionData?.startDate}
                        />
                      )}

                    {!subcriptionData?.isSubscriptionRequired &&
                      subcriptionData?.endDate && !userData?.userSubscriptions[0]?.isActive ? (
                      <ReminigDays
                        lable="End Date :"
                        date={subcriptionData?.endDate}
                      />
                    ) : null}
                  </div>

                  {subcriptionData?.isSubscriptionCancelled && (
                    <Typography
                      variant="body1"
                      sx={{
                        ...FontStyles.font16Prociono,
                        color: Color.color6,
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      {"Your subscription has been cancelled"}
                    </Typography>
                  )}

                  {subcriptionData?.isFreeTrialEnded &&
                    !subcriptionData?.isSubscriptionCancelled &&
                    !subcriptionData?.isSubscriptionRequired ? (
                    <ButtonUI
                      label="Cancel Subscription"
                      variant="outlined"
                      styles={{
                        whiteSpace: "nowrap",
                        backgroundColor: Color.color1,
                        width: isMatch ? "170px" : "200px",
                        marginTop: 15,
                        borderColor: Color.color1,
                      }}
                    // action={() => cancelSubcription()}
                    // isLoading={loadder}
                    />
                  ) : null}

                  {subcriptionData?.isSubscriptionCancelled ? (
                    <ButtonUI
                      label="Upgrade Now"
                      variant="outlined"
                      styles={{
                        whiteSpace: "nowrap",
                        backgroundColor: Color.color1,
                        width: isMatch ? "170px" : "200px",
                        marginTop: 15,
                        borderColor: Color.color1,
                      }}
                    // action={() => navigate("/dashboard/subscriptionplan")}
                    />
                  ) : null}

                  {/* <Typography sx={{ ...FontStyles.font16Prociono, color: Color.color4, width: '100%' }}>
                    Start Date : {userData?.userSubscriptions[0].startDate}
                  </Typography>
                  <Typography sx={{ ...FontStyles.font16Prociono, color: Color.color4, width: '100%' }}>
                    end Date : {userData?.userSubscriptions[0].endDate}
                  </Typography> */}
                  {/* <Grid container marginTop={3}>
                    <Grid xs={10} sm={6}>
                      <Typography
                        style={{
                          ...FontStyles.font24Prociono,
                          color: Color.color4,
                        }}
                      >
                        My Favorite
                      </Typography>
                    </Grid>
                    <Grid
                      xs={2}
                      sm={6}
                      container
                      alignItems="flex-end"
                      justifyContent={"flex-end"}
                    >
                      <Typography
                        textAlign={"right"}
                        style={{
                          ...FontStyles.font16Prociono,
                          color: Color.color6,
                        }}
                      >
                        Edit
                      </Typography>
                    </Grid>
                  </Grid> */}
                  {/* <Box
                    sx={{
                      width: "100%",
                      height: 1,
                      border: 1,
                      marginTop: 1,
                      marginBottom: 1,
                      color: Color.color17,
                    }}
                  ></Box>
                  <Typography
                    style={{
                      ...FontStyles.font24Prociono,
                      color: Color.color4,
                      width: "100%",
                      marginTop: 1,
                    }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    Subscription details
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: 1,
                      border: 1,
                      marginTop: 1,
                      color: Color.color17,
                    }}
                  ></Box> */}
                  {/* <div style={{ width: "100%", marginTop: "10px" }}>
                      <ReminigDays
                        lable="Member Since :"
                        date={userData?.createdDate}
                      />

                      {!paymentStatus?.isFreeTrialEnded ? (
                        <Grid container spacing={1}>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{
                                ...FontStyles.font16Prociono,
                                color: Color.color4,
                              }}
                            >
                              Remaining Days For Free Trial :
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body1"
                              sx={{
                                ...FontStyles.font16Prociono,
                                color: Color.color10,
                              }}
                            >
                              {paymentStatus?.remainingDaysForFreeTrial}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : null} */}

                  {/* {paymentStatus?.isFreeTrialEnded &&
                        paymentStatus?.startDate &&
                        paymentStatus?.endDate && (
                          <>
                            <ReminigDays
                              lable="Start Date :"
                              date={paymentStatus?.startDate}
                            />
                            <ReminigDays
                              lable="End Date :"
                              date={paymentStatus?.endDate}
                            />
                          </>
                        )}
                    </div> */}

                  {/* {paymentStatus?.isSubscriptionCancelled && (
                      <Typography
                        variant="body1"
                        sx={{
                          ...FontStyles.font16Prociono,
                          color: Color.color6,
                          marginTop: "10px",
                          width: "100%",
                        }}
                      >
                        {"Your subscription has been cancelled"}
                      </Typography>
                    )} */}

                  {/* {paymentStatus?.isFreeTrialEnded &&
                    !paymentStatus?.isSubscriptionCancelled &&
                    !paymentStatus?.isSubscriptionRequired ? (
                      <ButtonUI
                        label="Cancel Subscription"
                        variant="outlined"
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor: Color.color1,
                          width: isMatch ? "170px" : "200px",
                          marginTop: 15,
                          borderColor: Color.color1,
                        }}
                        action={() => cancelSubcription()}
                        isLoading={loadder}
                      />
                    ) : null} */}

                  {/* {paymentStatus?.isSubscriptionCancelled ? (
                      <ButtonUI
                        label="Upgrade Now"
                        variant="outlined"
                        style={{
                          whiteSpace: "nowrap",
                          backgroundColor: Color.color1,
                          width: isMatch ? "170px" : "200px",
                          marginTop: 15,
                          borderColor: Color.color1,
                        }}
                        action={() => navigate("/dashboard/subscriptionplan")}
                      />
                    ) : null} */}

                  {/* <Grid container marginTop={2}>
                    {TabBar.map((item, index: number) => {
                      return (
                        <Box
                          sx={{
                            border: 1,
                            borderLeft: index == 0 ? 1 : 0,
                            borderColor: Color.color19,
                            backgroundColor:
                              recipeTab == index ? Color.color1 : null,
                            width: "20%",
                            height: 40,
                          }}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          onClick={() => {
                            setRecipeTab(index);
                          }}
                        >
                          <Typography
                            textAlign={"center"}
                            style={{
                              ...FontStyles.font16Prociono,
                              color: Color.color4,
                            }}
                          >
                            {item.key}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Grid> */}
                  {/* <CardUi
                    headerAlignTop={false}
                    cardSize={cardSize}
                    gridSize={gridSize}
                    cardData={MealPrepRecipes}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
      <FullScreenLoadder open={isLoading} />
    </>
  );
};
export default UserDetail;
const Category = [
  {
    id: 1,
    name: "Vegetarian",
    label: "vegetarian",
    check: false,
  },
  {
    id: 2,
    name: "Nut-Free",
    label: "nutFree",
    check: false,
  },
  {
    id: 3,
    name: "Soy-Free",
    label: "soyFree",
    check: false,
  },
  {
    id: 4,
    name: "Egg-Free",
    label: "eggFree",
    check: false,
  },
  {
    id: 5,
    name: "Dairy Free",
    label: "dairyFree",
    check: false,
  },
  {
    id: 6,
    name: "Wheat Free",
    label: "wheatFree",
    check: false,
  },
];
