import { Card } from "@mui/material";

import RecipeTable from "./recipeTable";

function RecipeList() {
  return (
    <Card sx={{ marginTop: 5 }}>
      <RecipeTable />
    </Card>
  );
}

export default RecipeList;
