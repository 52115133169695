import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CardContent,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

import FontStyles from "src/utils/font";
import Color from "src/theme/colors";
import APIS from "src/config/api";
import { GET } from "src/config/https";
import { Parser } from "html-to-react";

import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import getUserData from "src/utils/getLocalStorageData";
import ButtonUI from "src/components/Button";
import OtherAttribute from "src/components/otherAttributeComponent";
import Footer from "src/components/Footer";
import HeaderText from "src/components/headerText";
import FullScreenLoadder from "src/components/fullScreenLoadder";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const RecipeDetail = () => {
  const location: any = useLocation();
  const [recipeDetail, setRecipeDetail] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let temp = location.state;
    getRecipeById();
  }, []);

  const navigate = useNavigate();

  const getRecipeById = async () => {
    setIsLoading(true);
    let tempUserData = await getUserData();
    if (!tempUserData) return;
    let params = {};

    await GET(
      `${APIS.GET_RECIPE_BY_ID}?id=${location.state.recipeData.id}`,
      params,
      tempUserData.jwtToken
    )
      .then(async (res: any) => {
        if (res) {
          await setRecipeDetail(res.result);
          setIsLoading(false);
        }
      })
      .catch((error) => { });
    setIsLoading(false);
  };

  const dietAttributesLable = (key: any) => {
    let data = DietAttributesList.find((x) => x.lable === key);
    return data.key;
  };

  const renderedItems = [];
  for (const key in recipeDetail.dietAttributes) {
    renderedItems.push(
      <div key={key}>
        <span>{key}: </span>
        <span>{recipeDetail.dietAttributes[key]}</span>
      </div>
    );
  }

  const firstCharUperCase = (str: string) => {
    const data = str.charAt(0).toUpperCase() + str.slice(1);
    return data;
  };

  return (
    <>
      <Helmet>
        <title>Recipe Detail</title>
      </Helmet>

      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            height: "80%",
            width: "99%",
            top: "175px",
            boder: 1,
            borderRadius: 1,
            paddingRight: 3,
            paddingLeft: 3,
            paddingBottom: 3,
            marginTop: "20px",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Button
              sx={{ height: "40px", width: "30px" }}
              onClick={() => {
                navigate("/management/recipes");
              }}
            >
              <ArrowBackIosIcon sx={{ fontSize: "15px" }} />
            </Button>
            <Box>
              <HeaderText name="Recipe Detail" />
            </Box>
            <Box sx={{ height: "40px", width: "30px" }}></Box>
          </Box>
          <Divider sx={{ marginBottom: 3 }} />
          {/* <Grid
            container
            columns={{ xs: 12, sm: 12, md: 12 }}
            spacing={{ xs: 2, md: 2, sm: 2 }}
          > */}
          {/* <Grid
              item
              xs={12}
              sm={12}
              md={6}
              display={"inline-flex"}
              justifyContent={"center"}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={recipeDetail.imagePath}
                  alt="recipeimage"
                  style={{ height: "300px", width: "565px" }}
                  // style={{ height: "250px", width: "390px" }}
                />
              </div>
            </Grid> */}


          <CardContent>
            <Box style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              alignSelf: "center",
            }}>
              <Grid item xs={12} sm={12} md={6}>
                <img
                  src={recipeDetail.imagePath}
                  alt="recipeimage"
                  style={{ height: "300px", width: "565px" }}
                // style={{ height: "250px", width: "390px" }}
                />

                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  Name
                </Typography>
                <Typography
                  style={{ ...FontStyles.font20Prociono, color: Color.color4 }}
                >
                  {recipeDetail.name}
                </Typography>
                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  Category
                </Typography>
                <Typography
                  style={{ ...FontStyles.font16Prociono, color: Color.color4 }}
                >
                  {recipeDetail.type && firstCharUperCase(recipeDetail.type)}
                </Typography>
                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  Ingredients
                </Typography>
                <Box
                  display="inline-flex"
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  alignSelf={"center"}
                  textAlign={"center"}
                  sx={{ width: "100%" }}
                >
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead sx={{ maxHeight: "10px" }}>
                      <TableRow>
                        <TableCell>
                          <Typography
                            style={{
                              ...FontStyles.font16Prociono,
                              fontFamily: "Playfair Display SC",
                              color: Color.color10,
                            }}
                          >
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              ...FontStyles.font16Prociono,
                              fontFamily: "Playfair Display SC",
                              color: Color.color10,
                            }}
                          >
                            Display Unit
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              ...FontStyles.font16Prociono,
                              fontFamily: "Playfair Display SC",
                              color: Color.color10,
                            }}
                          >
                            Display Quantity
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              ...FontStyles.font16Prociono,
                              fontFamily: "Playfair Display SC",
                              color: Color.color10,
                            }}
                          >
                            Converted Unit
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              ...FontStyles.font16Prociono,
                              fontFamily: "Playfair Display SC",
                              color: Color.color10,
                            }}
                          >
                            Converted Quantity
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            style={{
                              ...FontStyles.font16Prociono,
                              fontFamily: "Playfair Display SC",
                              color: Color.color10,
                            }}
                          >
                            Kroger Item Id
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            style={{
                              ...FontStyles.font16Prociono,
                              fontFamily: "Playfair Display SC",
                              color: Color.color10,
                            }}
                          >
                            Kroger Unit
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography
                            style={{
                              ...FontStyles.font16Prociono,
                              fontFamily: "Playfair Display SC",
                              color: Color.color10,
                            }}
                          >
                            Kroger Quantity
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recipeDetail && recipeDetail.ingredients
                        ? recipeDetail.ingredients.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography
                                style={{
                                  ...FontStyles.font16Prociono,
                                  color: Color.color4,
                                }}
                              >
                                {row?.name}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                style={{
                                  ...FontStyles.font16Prociono,
                                  color: Color.color4,
                                }}
                              >
                                {row?.unit}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                style={{
                                  ...FontStyles.font16Prociono,
                                  color: Color.color4,
                                }}
                              >
                                {Number(row?.quantity)}
                              </Typography>
                            </TableCell>

                            <TableCell component="th" scope="row">
                              <Typography
                                style={{
                                  ...FontStyles.font16Prociono,
                                  color: Color.color4,
                                }}
                              >
                                {row?.convertedUnit}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                style={{
                                  ...FontStyles.font16Prociono,
                                  color: Color.color4,
                                }}
                              >
                                {row?.convertedQuantity}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                style={{
                                  ...FontStyles.font16Prociono,
                                  color: Color.color4,
                                }}
                              >
                                {row?.krogerProductId}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                style={{
                                  ...FontStyles.font16Prociono,
                                  color: Color.color4,
                                }}
                              >
                                {row?.krogerUnit}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                style={{
                                  ...FontStyles.font16Prociono,
                                  color: Color.color4,
                                }}
                              >
                                {row?.krogerQuantity}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                        : null}
                    </TableBody>
                  </Table>
                </Box>

                {/*  */}
                {recipeDetail?.servingSize ? (
                  <>
                    <Typography
                      style={{
                        ...FontStyles.font24Prociono,
                        fontFamily: "Playfair Display SC",
                        color: Color.color10,
                        marginTop: 12,
                      }}
                    >
                      Serving Size
                    </Typography>
                    <Typography
                      style={{
                        // ...FontStyles.font10Prociono,

                        // color: Color.color5,
                        marginTop: 12,
                      }}
                    >
                      {recipeDetail.servingSize}
                    </Typography>
                  </>
                ) : null}
                {/*  */}
                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  Step to Make
                </Typography>
                <Typography
                  style={{
                    // ...FontStyles.font10Prociono,

                    // color: Color.color5,
                    marginTop: 12,
                  }}
                >
                  {Parser().parse(
                    recipeDetail.stepToMake ? recipeDetail.stepToMake : "<p></p> "
                  )}
                </Typography>
                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  Diet Attributes
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1, sm: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {recipeDetail && recipeDetail?.dietAttributes
                    ? Object.entries(recipeDetail?.dietAttributes).map(
                      ([key, value], index) => {
                        return (
                          // <Grid item xs={2} sm={2} md={4} key={index}>
                          //   <Box
                          //     sx={{
                          //       flexGrow: 1,
                          //       width: "110px",
                          //       height: "32px",
                          //     }}
                          //     display="inline-flex"
                          //     alignSelf={"center"}
                          //     alignItems={"center"}
                          //     justifyContent={"left"}
                          //   >
                          //     <Box
                          //       display="inline-flex"
                          //       flexDirection={"row"}
                          //       justifyContent={"center"}
                          //       alignItems={"center"}
                          //       alignSelf={"center"}
                          //       textAlign={"center"}
                          //     >
                          //       <IconButton sx={{ padding: 0 }}>
                          // {value ? (
                          //   <CheckBoxOutlinedIcon fontSize="small" />
                          // ) : (
                          //   <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                          // )}
                          //       </IconButton>
                          // <Typography
                          //   style={{
                          //     // ...FontStyles.font24Prociono,
                          //     color: Color.color4,
                          //     paddingLeft: 5,
                          //   }}
                          // >
                          //   {/* {key} */}
                          //   {dietAttributesLable(key)}
                          // </Typography>
                          //     </Box>
                          //   </Box>
                          // </Grid>
                          <Grid container item sm={6} md={12 / 2} xs={6}>
                            <Grid xs={3}>
                              <Typography
                                alignItems={"center"}
                                display={"flex"}
                                justifyContent={"center"}
                                sx={{ marginTop: "8px" }}
                              >
                                {value ? (
                                  <CheckBoxOutlinedIcon fontSize="small" />
                                ) : (
                                  <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                                )}
                              </Typography>
                            </Grid>
                            <Grid xs={9}>
                              <Grid xs={12}>
                                <Typography
                                  style={{
                                    ...FontStyles.font16Prociono,
                                    color: Color.color4,
                                    paddingLeft: 5,
                                  }}
                                >
                                  {/* {key} */}
                                  {dietAttributesLable(key)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      }
                    )
                    : null}
                </Grid>
                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  Calories
                </Typography>
                <Typography
                  style={{
                    ...FontStyles.font16Prociono,
                    color: Color.color4,
                    marginTop: 12,
                  }}
                >
                  {recipeDetail.calories}
                </Typography>
                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  Other Attributes
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 1, md: 1, sm: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <OtherAttribute data={recipeDetail.seasonal} name="Seasonal" />
                  <OtherAttribute data={recipeDetail.featured} name="Featured" />
                  <OtherAttribute data={recipeDetail.spicy} name="Spicy" />
                  <OtherAttribute
                    data={recipeDetail.quickPreparation}
                    name="Quick Preparation"
                  />
                </Grid>
                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  Cook Time
                </Typography>
                <Typography
                  style={{
                    ...FontStyles.font16Prociono,
                    color: Color.color4,
                    wordWrap: "break-word",
                  }}
                >
                  {recipeDetail.cookTime}
                </Typography>

                <Typography
                  style={{
                    ...FontStyles.font24Prociono,
                    fontFamily: "Playfair Display SC",
                    color: Color.color10,
                    marginTop: 12,
                  }}
                >
                  About
                </Typography>
                <Typography
                  style={{
                    // ...FontStyles.font24Prociono,
                    // color: Color.color4,
                    marginTop: 12,
                  }}
                >
                  {Parser().parse(
                    recipeDetail.about ? recipeDetail.about : "<p></p>"
                  )}
                </Typography>

                <Typography justifyContent={"space-between"} display={"flex"}>
                  <ButtonUI
                    variant={"contained"}
                    label="Edit Recipe"
                    styles={{
                      marginTop: 20,
                      width: "100%",
                      backgroundColor: Color.color1,
                      borderColor: Color.color1,
                    }}
                    onClick={() => {
                      navigate("/management/editrecipe", {
                        state: { data: recipeDetail, editRecipe: true },
                      });
                    }}
                  />
                </Typography>
              </Grid>
            </Box>
          </CardContent>
          {/* </Grid> */}
        </Box>
      </Box>

      <Footer />
      <FullScreenLoadder open={isLoading} />
    </>
  );
};
export default RecipeDetail;

const DietAttributesList = [
  {
    lable: "vegetarian",
    key: "Vegetarian",
  },
  {
    lable: "nutFree",
    key: "Nut-Free",
  },
  {
    lable: "dairyFree",
    key: "Dairy-Free",
  },
  {
    lable: "soyFree",
    key: "Soy-Free",
  },
  {
    lable: "eggFree",
    key: "Egg-Free",
  },
  {
    lable: "wheatFree",
    key: "Wheat-Fee",
  },
  {
    lable: "sesameFree",
    key: "Sesame-free",
  },
];
