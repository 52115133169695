import { Grid, Typography } from "@mui/material";
import moment from "moment";
import Color from "src/theme/colors";
import FontStyles from "src/utils/font";

const ReminigDays = ({ lable, date }: ReminigDaysProps) => {
  return (
    <Grid container spacing={0.5}>
      <Grid item>
        <Typography
          variant="body1"
          sx={{
            ...FontStyles.font16Prociono,
            color: Color.color4,
          }}
        >
          {lable}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          sx={{
            ...FontStyles.font16Prociono,
            color: Color.color10,
          }}
        >
          {moment(date).format("MMMM Do YYYY")}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ReminigDays;
interface ReminigDaysProps {
  lable: string;
  date: any;
}
