import { Backdrop, CircularProgress } from "@mui/material";

const FullScreenLoadder = ({ open }: fullScreenLoadderProps) => {
  return (
    <Backdrop
      sx={{ color: "blue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
export default FullScreenLoadder;

interface fullScreenLoadderProps {
  open: boolean;
}
