import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const PieChart = ({ dataLable, dataValue, headingData }: PieChartData) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        const myChartRef = chartRef.current.getContext('2d');

        // Destroy the previous chart instance if it exists
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        chartInstanceRef.current = new Chart(myChartRef, {
            type: 'pie',
            data: {
                // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                labels: dataLable,
                datasets: [{
                    // data: [12, 19, 3, 5, 2, 3],
                    data: dataValue,
                    backgroundColor: [
                        "rgba(255, 99, 132, 0.2)",
                        "rgba(54, 162, 235, 0.2)",
                        "rgba(255, 206, 86, 0.2)",
                        "rgba(75, 192, 192, 0.2)",
                        "rgba(153, 102, 255, 0.2)",
                        "rgba(255, 159, 64, 0.2)",
                        'rgba(255, 0, 0, 0.2)',
                        'rgba(0, 255, 0, 0.2)',
                        'rgba(0, 0, 255, 0.2)',
                        'rgba(0, 128, 0, 0.2)',
                        "rgba(253,180,92,0.2)",
                        'rgba(255, 165, 0, 0.2)',

                    ],
                    borderColor: [
                        "rgba(255, 99, 132, 1)",
                        "rgba(54, 162, 235, 1)",
                        "rgba(255, 206, 86, 1)",
                        "rgba(75, 192, 192, 1)",
                        "rgba(153, 102, 255, 1)",
                        "rgba(255, 159, 64, 1)",
                        'rgba(255, 0, 0, 1)',
                        'rgba(0, 255, 0, 1)',
                        'rgba(0, 0, 255, 1)',
                        'rgba(0, 128, 0, 1)',
                        "rgba(253,180,92,1)",
                        'rgba(255, 165, 0, 1)',

                    ],
                    borderWidth: 1,
                }],
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        // text: 'Pie Chart Example',
                        text: headingData,
                        font: {
                            size: 16,
                        },
                    },
                },
            },
        });

        // Clean up the chart instance when the component unmounts
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [dataLable, dataValue]);

    return <canvas ref={chartRef} />;
};

export default PieChart;

interface PieChartData {
    dataLable: any
    dataValue: any
    headingData: any
}