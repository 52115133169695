import axios from "axios";
import { parseAxiosError } from "./parseAxiosError";
import loginNavigate from "src/utils/loginnavigate";

const commonHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const axiosInstance = axios.create({
  baseURL: "https://dev-api.miseenplacemeals.com/api/",
});

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async (error) => {
    if (error.response.status === 500) {
      return Promise.reject(error);
    }
    if (error.response.status === 404) {
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      await loginNavigate(); // unauthorize - redirect to login screen
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export const GET = async (
  url: string,
  params?: { [name: string]: string },
  token?: string
) => {
  const authorization = "Bearer" + " " + token;

  return new Promise(function (resolve, reject) {
    axiosInstance({
      params,
      method: "get",
      url: url,
      headers: {
        ...commonHeaders,
        Authorization: authorization,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch(async (e) => {
        const error = await parseAxiosError(e);
        reject(error);
      });
  });
};

export const POST = async (url: string, data?: any, token?: string) => {
  const authorization = "Bearer" + " " + token;

  return new Promise(function (resolve, reject) {
    axiosInstance({
      data,
      method: "post",
      url: url,
      headers: {
        ...commonHeaders,
        Authorization: authorization,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch(async (e) => {
        const error = await parseAxiosError(e);
        reject(error);
      });
  });
};

export const PUT = async (url: string, data?: any, token?: string) => {
  const authorization = "Bearer" + " " + token;

  return new Promise(function (resolve, reject) {
    axiosInstance({
      data,
      method: "put",
      url: url,
      headers: {
        ...commonHeaders,
        Authorization: authorization,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch(async (e) => {
        const error = await parseAxiosError(e);
        reject(error);
      });
  });
};

export const DELETE = async (url: string, token?: string) => {
  const authorization = "Bearer" + " " + token;

  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: "delete",
      url: url,
      headers: {
        ...commonHeaders,
        Authorization: authorization,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        }
      })
      .catch(async (e) => {
        const error = await parseAxiosError(e);
        reject(e);
      });
  });
};
