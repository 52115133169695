import { Helmet } from "react-helmet-async";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "src/components/Footer";
import RecipeList from "./recipeList";

const Recipe = () => {
  const navigate = useNavigate();

  const onclick = () => {
    navigate("/management/addrecipe");
  };

  return (
    <>
      <Helmet>
        <title>Recipes</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <RecipeList />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};
export default Recipe;
