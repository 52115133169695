import { Typography } from "@mui/material";
import Color from "src/theme/colors";

const ValidationError = ({ formErrors }: ValidationError) => {
  return (
    <>
      {formErrors ? (
        <Typography style={styles.validationError}>{formErrors}</Typography>
      ) : null}
    </>
  );
};
export default ValidationError;

const styles = {
  validationError: {
    marginTop: "1px",
    color: Color.red,
    fontSize: "12px",
  },
};

type ValidationError = {
  formErrors: string;
};
